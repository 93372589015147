import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, from, fromEvent, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AppConfiguration } from '../app.configuration';
import { ConfigurationService } from '../core/config/configuration.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '../shared/translate.service';
import { TokenManagerService } from '../core/token/token-manager.service';
import { ProductosService } from '../business/productos.service';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  loginUser;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser$: Observable<any>;

  private datosUserSubject: BehaviorSubject<any>;
  public datosUser$: Observable<any>;
  
  private userAdminSubject: BehaviorSubject<any>;
  public userAdmin$: Observable<any>;

  private passCaducadaSubject: BehaviorSubject<any>;
  public passCaducada$: Observable<any>;

  private userNameSubject: BehaviorSubject<any>;
  public userName$ : Observable<any>;

  private oldPassSubject: BehaviorSubject<any>;
  public oldPass$: Observable<any>;

  logged: EventEmitter<boolean> = new EventEmitter();
  setlang: EventEmitter<string> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private router: Router,
    private config: AppConfiguration,
    private tokenManager: TokenManagerService,
    private configurationService: ConfigurationService,
    private translateService: TranslateService
  ) {

    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser$ = this.currentUserSubject.asObservable();

    this.datosUserSubject = new BehaviorSubject<any>(undefined);
    this.datosUser$ = this.datosUserSubject.asObservable();
    
    this.userAdminSubject = new BehaviorSubject<any>(false);
    this.userAdmin$ = this.userAdminSubject.asObservable();

    this.passCaducadaSubject = new BehaviorSubject<any>(false);
    this.passCaducada$ = this.passCaducadaSubject.asObservable();

    this.userNameSubject = new BehaviorSubject<any>(undefined);
    this.userName$ = this.userNameSubject.asObservable();

    this.oldPassSubject = new BehaviorSubject<any>(undefined);
    this.oldPass$ = this.oldPassSubject.asObservable();


    this.setCurrentUserValue(JSON.parse(sessionStorage.getItem('currentUser')));

  }
  public get currentUserValue() {
    return this.currentUserSubject.value;
  }
  setCurrentUserValue(user) {
    this.currentUserSubject.next(user);
  }
  public get datosUserValue() {
    return this.datosUserSubject.value;
  }
  setDatosUserValue(user) {
    this.datosUserSubject.next(user);
  }
  
  public get userAdminValue() {
    return this.userAdminSubject.value;
  }
  setUserAdminValue(user) {
    this.userAdminSubject.next(user);
  }

  public get passCaducadaValue() {
    return this.passCaducadaSubject.value;
  }
  setPassCaducadaValue(valor) {
    this.passCaducadaSubject.next(valor);
  }

  public get userNameValue(){
    return this.userNameSubject.value;
  }
  setUserNameValue(userName){
    this.userNameSubject.next(userName);
  }
  public get oldPassValue(){
    return this.oldPassSubject.value;
  }
  setOldPassValue(pass){
    this.oldPassSubject.next(pass);
  }

  getUser(){
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  registro(user, param, componente, publicidad, idioma, verificarRegistro) {
    
    let userSesion = JSON.parse(sessionStorage.getItem('currentUser'));

    const body = {
      AlbaranSinDescuento: 0,
      Automatico: verificarRegistro == '1'? 0:1,
      bDescuento: 0,
      CIFoDNI: user.documento 
        ? user.documento 
        : user.CIFoDNI
          ? user.CIFoDNI 
          : userSesion 
            ? userSesion.CIFoDNI 
              ? userSesion.CIFoDNI 
              : ""
            : "",
      CIFoDNIFiscal: null,
      CodigoCliente: userSesion && userSesion.CodigoCliente ? userSesion.CodigoCliente : 0,
      Contrasena: user.password? user.password : user.contrasena? user.contrasena : "",
      CP: user.codigopostal  ? user.codigopostal : "",
      CPFiscal: null,
      DeBaja: verificarRegistro == '1'? 1:0,
      DiaPago: null,
      Direccion: user.direccion  ? user.direccion : "",
      DireccionCobro: null,
      DireccionFiscal: null,
      EMail: user.email ? user.email : user.EMail ? user.EMail : "",
      EMail2: null,
      EsTuristico: 0,
      Fax: null,
      Fax2: user.fechanacimiento ? user.fechanacimiento.format('DD/MM/YYYY') : "",
      FechaAlta: null,
      FechaBaja: null,
      FechaModificacionPubInterna: null,
      Idioma:idioma,
      ImprimirEnTicket: 0,
      Localidad: user.Localidad ? user.Localidad : "",
      LocalidadFiscal: null,
      LugarCobro: null,
      Nacionalidad: '',
      Nombre: user.nombre ? user.nombre  :  user.Nombre ? user.Nombre : user.nombreCliente? user.NombreCliente: user.NombreClienteAdicional? user.NombreClienteAdicional: user.NombreClienteOriginal? user.NombreClienteOriginal: "",
      NombreCompleto: user.razonsocial  ? user.razonsocial  : user.nombrecentro ? user.nombrecentro : user.NombreClienteOriginal? user.NombreClienteOriginal :user.nombre? user.nombre +" " + (user.apellidos ? user.apellidos+" " : "") + (user.apellido2contacto ? user.apellido2contacto : "") : ""  ,
      NombreCompletoFiscal: null,
      NombreEnlaceMenu: '',
      NumCuenta: null,
      CodigoPais: user.CodigoPais ? user.CodigoPais : "",
      Pais: user.pais  ? user.pais : user.Pais ? user.Pais : "",
      PaisFiscal: null,
      PersonaCobro: user.nombreresponsable ? user.nombreresponsable :null,
      PrefijoInternacional: '',
      PrimerApellido: user.apellidos ? user.apellidos :  user.PrimerApellidoCliente ? user.PrimerApellidoCliente : "",
      Provincia: user.Provincia ? user.Provincia : user.provincia  ? user.provincia : '',
      ProvinciaFiscal: null,
      PublicidadExterna: 1,
      PublicidadInterna: publicidad? "1" : "0",
      PublicidadOrganizador: 1,
      rDescuento: 0.0,
      RedondeoPrecios: 0,
      RegistrarModificar: param,
      SegundoApellido: user.apellido2contacto ? user.apellido2contacto : user.SegundoApellidoCliente ? user.SegundoApellidoCliente : "",
      Telefono: user.telefono ? user.telefono : "",
      Telefono2: null,
      TicketSinDescuento: 0,
      TipoClienteId: user.tipocliente,
      TipoDocumento: user.tipodocumento
        ? user.tipodocumento
        : user.TipoDocumento
          ? user.TipoDocumento
          : userSesion
            ? userSesion.TipoDocumento
              ? userSesion.TipoDocumento
              : ""
            : "",
      TipoDto: 'DT',
      TipoPagoId: null,
      Usuario: user.email? user.email  : user.EMail? user.EMail:"",
      PoliticaPrivacidad: {
        Apellidos: "",
        CodigoCliente: JSON.parse(sessionStorage.getItem("currentUser")) ? JSON.parse(sessionStorage.getItem("currentUser")).CodigoCliente? JSON.parse(sessionStorage.getItem("currentUser")).CodigoCliente : 0 : 0,
        ConexionIacpos: 0,
        DNI: "",
        EMail: user.email ? user.email : user.EMail ? user.EMail : "",
        EnlacePolitica: this.translateService.data.ENLACEPOLITICAPRIVACIDAD,
        NombreFormulario: componente,
        NombreProyecto: environment["chClaveEmpresa"],
        Nombres: "",
        PublicidadInterna: publicidad ? "1" : "0",
        TipoPolitica: 0
      }
    };

    return this.http.post(`${this.config.getConfig('API_URL')}/api/ClienteyClienteInternet`, body).pipe(map((res: any) => {
      if (res.DatosResult && res.DatosResult !=null && res.DatosResult.Avisos && res.DatosResult.Avisos!=null) {
        if(param==2){

          this.changeSessionData(user)
        }
      }
      return res;
    }));
  }

  registerFromMyAccount(user, param, componente, publicidad, idioma, verificarRegistro) {
    const userSesion = JSON.parse(sessionStorage.getItem('currentUser'));
    console.log(user.tipocliente, userSesion.TipoClienteId)
    const body= {
      AlbaranSinDescuento: 0,
      Automatico: verificarRegistro == '1' ? 0 : 1,
      bDescuento: 0,
      CIFoDNI: user.documento ? user.documento : user.CIFoDNI ? user.CIFoDNI : userSesion.CIFoDNI ? userSesion.CIFoDNI : "",
      CIFoDNIFiscal: null,
      CodigoCliente: userSesion && userSesion.CodigoCliente ? userSesion.CodigoCliente : 0,
      Contrasena: user.password? user.password : user.contrasena? user.contrasena : "",
      CP: user.codigopostal  ? user.codigopostal : "",
      CPFiscal: null,
      DeBaja: verificarRegistro == '1'? 1:0,
      DiaPago: null,
      Direccion: user.direccion  ? user.direccion : "",
      DireccionCobro: null,
      DireccionFiscal: null,
      EMail: user.email ? user.email : user.EMail ? user.EMail : "",
      EMail2: null,
      EsTuristico: 0,
      Fax: null,
      Fax2: null,
      FechaAlta: null,
      FechaBaja: null,
      FechaModificacionPubInterna: null,
      Idioma: idioma,
      ImprimirEnTicket: 0,
      Localidad: '',
      LocalidadFiscal: null,
      LugarCobro: null,
      Nacionalidad: userSesion.Nacionalidad ?? '',
      Nombre: user.nombre ? user.nombre  :  user.Nombre ? user.Nombre : user.nombreCliente? user.NombreCliente: user.NombreClienteAdicional? user.NombreClienteAdicional: user.NombreClienteOriginal? user.NombreClienteOriginal: "",
      NombreCompleto: user.razonsocial  ? user.razonsocial  : user.nombrecentro ? user.nombrecentro : user.NombreClienteOriginal? user.NombreClienteOriginal :user.nombre? user.nombre +" " + (user.apellidos ? user.apellidos+" " : "") + (user.apellido2contacto ? user.apellido2contacto : "") : ""  ,
      NombreCompletoFiscal: null,
      NombreComercial: userSesion.NombreComercial ?? '',
      NombreEnlaceMenu: '',
      NumCuenta: null,
      Pais: userSesion.Pais ?? '',
      PaisFiscal: null,
      PersonaCobro: user.nombreresponsable ? user.nombreresponsable :null,
      PrefijoInternacional: '',
      PrimerApellido: user.apellidos ? user.apellidos :  user.PrimerApellidoCliente ? user.PrimerApellidoCliente : "",
      Provincia: user.provincia  ? user.provincia : user.Provincia ? user.Provincia : '',
      ProvinciaFiscal: null,
      PublicidadExterna: 1,
      PublicidadInterna: publicidad ? '1' : '0',
      PublicidadOrganizador: 1,
      rDescuento: 0.0,
      RedondeoPrecios: 0,
      RegistrarModificar: param,
      SegundoApellido: user.apellido2contacto ? user.apellido2contacto : user.SegundoApellidoCliente ? user.SegundoApellidoCliente : "",
      Telefono: user.telefono ? user.telefono : "",
      Telefono2: null,
      TicketSinDescuento: 0,
      TipoClienteId: userSesion && userSesion.TipoClienteId ? userSesion.TipoClienteId : 1, ///A
      TipoDocumento: user.tipodocumento ? user.tipodocumento : user.TipoDocumento ? user.TipoDocumento : userSesion.TipoDocumento ? userSesion.TipoDocumento : '',
      TipoDto: 'DT',
      TipoPagoId: null,
      Usuario: user.email? user.email  : user.EMail? user.EMail:"",
      PoliticaPrivacidad: {
        Apellidos: "",
        CodigoCliente: JSON.parse(sessionStorage.getItem("currentUser")) ? JSON.parse(sessionStorage.getItem("currentUser")).CodigoCliente? JSON.parse(sessionStorage.getItem("currentUser")).CodigoCliente : 0 : 0,
        ConexionIacpos: 0,
        DNI: "",
        EMail: user.email ? user.email : user.EMail ? user.EMail : "",
        EnlacePolitica: this.translateService.data.ENLACEPOLITICAPRIVACIDAD,
        NombreFormulario: componente,
        NombreProyecto: environment["chClaveEmpresa"],
        Nombres: "",
        PublicidadInterna: publicidad ? "1" : "0",
        TipoPolitica: 0
      }
    };

    return this.http.post(`${this.config.getConfig('API_URL')}/api/ClienteyClienteInternet`, body).pipe(map((res: any) => {
      if (res.DatosResult && res.DatosResult !=null && res.DatosResult.Avisos && res.DatosResult.Avisos!=null) {
        if(param==2){

          this.changeSessionData(user)
        }
      }
      return res;
    }));
  }

  changeSessionData(data)
  {
    let user = JSON.parse(sessionStorage.getItem('currentUser'));
    if(user){
     Object.keys(data).forEach(element => {
        user[element] = data[element]
      });
      sessionStorage.setItem('currentUser', JSON.stringify(user));
    }
  }

  updateCliente(codigoCliente){
    const body = {
      CodigoCliente: codigoCliente
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/TraerCliente`, body).pipe( map((res:any) => {
      if (res && res.DatosResult) {
        return res;
      }
      return of(null);
    }));
  }

  login(user,desdeAngular?,desdeLogin?,desdeRegister?) {
    const body = {
      Usuario: desdeRegister ? user.email ? user.email : user.EMail ? user.EMail : "" : user.nombre,
      Password: user.contrasena ? user.contrasena:user.password?user.password: user.nuevaContrasena ? user.nuevaContrasena: "",
      DesdeAngular: desdeAngular,
      DesdeLogin: desdeLogin
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/TraerCliente`, body).pipe(map((res: any) => {
      if (!res.DatosResult) {
        return res
      }
      this.loginUser = res.DatosResult
        
      /* this.loginUser = {
        NombreCliente: res.DatosResult.NombreCliente,
        Contrasena: res.DatosResult.Contrasena,
        NombreClienteOriginal: res.DatosResult.NombreClienteOriginal,
        Telefono: res.DatosResult.Telefono        
      }; */

      sessionStorage.setItem('currentUser', JSON.stringify(this.loginUser));

      this.setCurrentUserValue(this.loginUser);
      return res;
    }));
  }

  comprobarContrasena(currentUser, formValue) {
    const body = {
      Usuario: currentUser.NombreCliente,
      Password: formValue.contrasena
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/GetCliente`, body).pipe(map((res: any) => {
      if (!res.DatosResult.NombreCliente) {
        return null;
      }
      return res;
    }));
  }

  devolverContrasenaEncript(currentUser, formValue) {
    const body = {
      Usuario: currentUser.NombreCliente,
      Password: formValue.nuevaContrasena
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/GetCliente`, body).pipe(map((res: any) => {
      if (!res.DatosResult.NombreCliente) {
        return null;
      }
      return res;
    }));
  }

  logout(cancelarAforos: boolean = true) {
    sessionStorage.removeItem("currentUser");
    sessionStorage.removeItem("cliente");
    sessionStorage.removeItem("cesta");
    sessionStorage.removeItem("admin");
    sessionStorage.removeItem("estaPagada");
    this.setCurrentUserValue(undefined);    
    if (cancelarAforos) {
      this.configurationService.cancelarAforoCarrito().pipe().subscribe(r => {
        //this.configurationService.borrarcarrito.emit(true);
        this.currentUserSubject.next(null);
        window.location.href ="/tienda/productos";
      });
    } else {
      this.currentUserSubject.next(null);
      window.location.href ="/tienda/productos";
    }   
  }

  //****NO SE USA ***** No se llama a ese metodo
  // cambiaNuevaContrasena(user, form) {
  //   const body = {
  //     RegistrarModificar: 2,
  //     Usuario: user.NombreCliente,
  //     Nombre: user.NombreClienteOriginal,
  //     PrimerApellido: null,
  //     SegundoApellido: null,
  //     NombreCompleto: user.NombreClienteOriginal,
  //     NombreCompletoFiscal: null,
  //     Contrasena: form.contrasena,
  //     Direccion: '',
  //     DireccionFiscal: null,
  //     Localidad: '',
  //     LocalidadFiscal: null,
  //     CP: '',
  //     CPFiscal: null,
  //     Provincia: '',
  //     ProvinciaFiscal: null,
  //     Pais: '',
  //     PaisFiscal: null,
  //     Telefono: user.Telefono,
  //     LugarCobro: null,
  //     PersonaCobro: null,
  //     DireccionCobro: null,
  //     NumCuenta: null,
  //     TipoPagoId: null,
  //     bDescuento: 0,
  //     rDescuento: 0.0,
  //     FechaAlta: null,
  //     FechaBaja: null,
  //     DiaPago: null,
  //     Telefono2: null,
  //     Fax: null,
  //     Fax2: null,
  //     CIFoDNI: '',
  //     CIFoDNIFiscal: null,
  //     EMail: user.NombreCliente,
  //     EMail2: null,
  //     DeBaja: 0,
  //     TicketSinDescuento: 0,
  //     AlbaranSinDescuento: 0,
  //     ImprimirEnTicket: 0,
  //     TipoDto: 'DT',
  //     RedondeoPrecios: 0,
  //     EsTuristico: 0,
  //     PublicidadInterna: 1,
  //     PublicidadExterna: 1,
  //     PublicidadOrganizador: 1,
  //     FechaModificacionPubInterna: null,
  //     CodigoCliente: 0,
  //     TipoClienteId: 2,
  //     Nacionalidad: '',
  //     PrefijoInternacional: '',
  //     NombreEnlaceMenu: '',
  //     TipoDocumento: '',
  //     NuevaContrasena: form.nuevaContrasena
  //   };
  //   return this.http.post(`${this.config.getConfig('API_URL')}/api/RegistroClienteyClienteInternet`, body).pipe(map((res: any) => {
  //     if (!res.DatosResult) {
  //       return null;
  //     }     
  //     return res;
  //   }));
  // }

  cambiaContrasenaCaducada(user, form) {
    const body = {
      RegistrarModificar: 3,
      Usuario: user.NombreCliente,
      Contrasena: form.nuevaContrasena
      //NuevaContrasena: form.nuevaContrasena
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/ClienteyClienteInternet`, body).pipe(map((res: any) => {
      if (!res.DatosResult) {
        return null;
      }
      return res;
    }));
  }

  existeToken(token){
    const body = {
      Token: token,
      HorasValido: '24',
    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/ExisteToken`, body).pipe(map((res: any) => {

      return res;
    }));
  }

  cambiaContrasena(user, email, token) {
    const body = {
      Email: email,
      Token: token,
      HorasValido: '',
      Contrasena: user.repiteContrasena
    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/ModificarContrasenaPorTokenPrivada`, body).pipe(map((res: any) => {

      return res;
    }));
  }

  recoverContrasena(user) {
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      Email: user.email ? user.email : user.EMail ? user.EMail : "" ,
    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/CrearTokenCambioContrasenaMail`, body).pipe(map((res: any) => {
      return res;
    }));
  }

  changeContrasena(user) {    
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      Email: user.email ? user.email : user.EMail ? user.EMail : "" ,
    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/CrearTokenCambioContrasena`, body).pipe(map((res: any) => {
      return res;
    }));
  }

  comprasUsuario() {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    const body = {
      Usuario: user.NombreCliente,
      Password: user.Contrasena,
      TipoUsuario: 1,
      NombreCliente: user.NombreClienteOriginal,
      NumeroPedido: '',
      ClaveReserva: '',
      Pagada: '1',
      Anulada: '2',
      Vendida: null,
      Facturada: '2',
      Email: '',
      FechaDesdeVisita: '',
      FechaHastaVisita: '',
      FechaCreacionDesde: '',
      FechaCreacionHasta: '',
      FechaSolicitudDesde: '',
      FechaSolicitudHasta: '',
      ClienteReserva: '',
      Contacto: '',
      TipoReserva: '',
      TipoEntradaId: '-1',
      TipoBonoId: '-1',
      EstadoSolicitud: '',
      ClaveReservaAnexa: null,
      NumFactura: '',
      NumFacturaComision: null,
      HoraSolicitudDesde: '',
      HoraSolicitudHasta: '',
      HoraCreacionDesde: '',
      HoraCreacionHasta: '',
      HoraVisitaDesde: '',
      HoraVisitaHasta: '',
      NombreGrupo: '',
      NumeroRegistros: 15,
      Pagina: 1,
      EnlaceMenu: null,
      OrdenarPor: null,
      DireccionOrden: null,
      ExternoId: '-1',
      NombreUsuarioBD: '',
      ClienteId: '-1',
      Telefono: '',
      ClaveReservaOrigen: null
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/BuscadorReservas`, body).pipe(map((res: any) => {
      return res;
    }));
  } 

  getClientes(texto, field) {
    let nombreOriginal = '';
    let nombreContacto = '';
    let ciudad = '';
    if (field) {
      switch (field.busqueda.campo) {
        case 'NombreOriginal':
          nombreOriginal = texto;
          break;
        case 'NombreContacto':
          nombreContacto = texto;
          break;
        case 'LocalidadContacto':
          ciudad = texto;
          break;
      }
    } else {
      nombreOriginal = texto;
    }

    const body = {
      EnlaceMenu: null,
      Usuario: '',
      Password: '',
      NombreOriginal: nombreOriginal,
      NombreCliente: '',
      Apellido1: '',
      Apellido2: '',
      Responsable: '',
      CifDni: '',
      Telefono: '',
      Email: '',
      Direccion: '',
      CodigoPostal: '',
      Poblacion: ciudad,
      Provincia: '',
      Pais: '',
      CodigoPais: '', //Yaribel 20210531 Añadimos codigo pais al objeto clente
      TipoCliente: '',
      FechaDesdeAlta: '',
      FechaHastaAlta: '',
      IncluirContactos: field ? field.busqueda.IncluirContactos : '1',
      NombreContacto: nombreContacto

    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/BuscadorCliente`, body).pipe(map((res: any) => {
      return res.ResultadoBuscadorClientes;
    }));
  }

  getOperaciones(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    let peticion: any = {};

    const codigoCliente = {
        iCodigoCliente: currentUser.CodigoCliente
    };

    peticion.chParams = codigoCliente;

    const clienteApi = {
      Id: this.tokenManager.currentTokenValue.sessionId,
      Token: this.tokenManager.currentTokenValue.token
    };

    const conexionIacpos = this.tokenManager.currentStorageConexionValue;
    peticion.chParams = codigoCliente;
    peticion.ClienteApi = clienteApi;
    peticion.ConexionIacpos = conexionIacpos;
    peticion.ClaveEmpresa = this.config.getConfig('chClaveEmpresa');
    peticion.Proyecto = this.config.getConfig('chNombreProyecto');


    if (pagination) {
      peticion.paginator = pagination
      peticion.filters = filters
    }

    //Enviamos la peticion
    let respuesta = this.http.post<any>(this.config.getConfig('API_URL') + 'api/BuscadorOperacionesCliente', peticion, httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return respuesta;
  }


    getPDFOperacion(Localizador,email,Nombre, Contrasena) {
      //JSON.parse(sessionStorage.getItem('currentUser'))  
    let peticion: any = {};
    peticion.claveReserva = Localizador;
    peticion.NumeroPedido="";
    peticion.EnvioMail = email;
    peticion.Nombre = Nombre;
    peticion.Contrasena = Contrasena;
    peticion.TipoCliente = 1;
    peticion.NuevoSistema = 1;
    //Enviamos la peticion
          

    const clienteApi = {
      Id: this.tokenManager.currentTokenValue.sessionId,
      Token: this.tokenManager.currentTokenValue.token
    };

    const conexionIacpos = this.tokenManager.currentStorageConexionValue;
    peticion.ClienteApi = clienteApi;
    peticion.ConexionIacpos = conexionIacpos;

    let respuesta = this.http.post<any>(this.config.getConfig('API_URL') + 'api/GenerarPDF', peticion, httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return respuesta;
    }

    anularOperacion(Localizador, username, userpass) {
      let peticion: any = {};
      peticion.Localizador = Localizador;
      peticion.ControlarAforo = 1;
      peticion.EsCliente = 1;

      peticion.UserName = username;
      peticion.UserPass = userpass;
      //Enviamos la peticion

      const clienteApi = {
        Id: this.tokenManager.currentTokenValue.sessionId,
        Token: this.tokenManager.currentTokenValue.token
      };
  
      const conexionIacpos = this.tokenManager.currentStorageConexionValue;
      peticion.ClienteApi = clienteApi;
      peticion.ConexionIacpos = conexionIacpos;
  
            
      let respuesta = this.http.post<any>(this.config.getConfig('API_URL') + 'api/AnularVentaReserva', peticion, httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
      return respuesta;
    }

    cerrarSesion(){
      const body ={
        Id: this.tokenManager.currentTokenValue.sessionId,
        Token: this.tokenManager.currentTokenValue.token
      };
      return this.http.post(`${this.config.getConfig('API_URL')}api/EliminarClienteEnToken`, body).pipe(map((res: any) => {
        return res;
      }));
    }

    getErrorTranslateLogin(error:any){
      switch (error) { 
        case "-2":
          return this.translateService.data.usuarioOEmailIncorrecto;
          break;
        case "-5":
          return this.translateService.data.usuarioBloqueado;
          break;
        case "-6":
          return this.translateService.data.contrasenaCaducada;
          break;         
        default:
          return this.translateService.data.usuarioNoEncontrado;
          break;
     }
    }

    getErrorTranslate(error:any){

      var n;
      if (error.startsWith('L')) {    
        n = parseInt(error.substring(1));
        error="L";
      }
  
      switch (error) {
        case "0":
          return this.translateService.data.errorNoModificar;
          break;
        case "-1":
          return this.translateService.data.noConexionIacpos;
          break;
        case "RN03":
          return this.translateService.data.indicarRegistro;
          break;
        case "01":
          return this.translateService.data.errorServidor;
          break;
        case "E0":
          return this.translateService.data.errorNoHaModificado;
          break;
        case "E2":
          return this.translateService.data.mismoNombreCliente;
          break;
        case "E3":
          return this.translateService.data.emailExistente;
          break;
        case "E4":
          return this.translateService.data.clienteExistente;
          break;
        case "E5":
          return this.translateService.data.cifExistente;
          break;
        case "E6":
          return this.translateService.data.noCodigoCliente;
          break;
        case "E7":
          return this.translateService.data.contrasenaNoRepetir;
          break; 
        case "R0":
          return this.translateService.data.contrasenaRepetida;
          break;
        case "L":
          // return this.translateService.data.contrasenaCriteriosError+n+this.translateService.data.contrasenaCriteriosError2;
          return this.translateService.data.contrasenaCriteriosError1+ ' '+ n + ' '+this.translateService.data.caracteres;
          break;
  /*       case "L8":
          return this.translateService.data.contrasenaCriteriosError;
          break; */
        case "C1":
          return this.translateService.data.contrasenaNumerosLetras;
          break;
        case "C2":
          return this.translateService.data.contrasenaNumerosLetrasCaracteresEspeciales;
          break;
        case "C3":
          return this.translateService.data.contrasenaNumerosLetrasCaracteresEspecialesMayusculasMinusculas;
          break;
        case "C4":
          return this.translateService.data.tokenCaducadoNoExiste;
          break;    
        default:
          return this.translateService.data.noControlado;
          break;  
      }
    }
}
