import {
	Component,
	ElementRef,
	Input,
	LOCALE_ID,
	OnInit,
	ViewEncapsulation,
} from "@angular/core";
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidatorFn,
	Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { forkJoin, Subject, Subscription } from "rxjs";
import { takeUntil, takeWhile } from "rxjs/operators";
import { ProductosService } from "src/app/business/productos.service";
import { VentaService } from "src/app/core/services/venta.service";
import { ModalService } from "src/app/shared/modal.service";
import { TranslateService } from "src/app/shared/translate.service";
import { UserService } from "../user.service";
import { GlobalService } from "src/app/shared/services/global/global.service";
import { HttpBodyGetProvincias } from "src/app/business/productos.service.interface";
import { TokenManagerService } from "src/app/core/token/token-manager.service";
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
} from "@angular/material/core";
import { AppDateAdapter } from "src/app/shared/classes/dataAdapter/date-format.adapter";
import { MY_FORMATS } from "src/app/shared/components/filter/filter.component";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Moment } from "moment";
import { InitService } from "src/app/core/init/init.service";

@Component({
	selector: "app-signup",
	templateUrl: "./signupV2.component.html",
	styleUrls: ["./signup.component.scss"],
	providers: [
		{
			provide: DateAdapter,
			useClass: AppDateAdapter,
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: MY_FORMATS,
		},
		{ provide: LOCALE_ID, useValue: "es" },
		{ provide: MAT_DATE_LOCALE, useValue: "es" },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
	],
})
export class SignupComponent implements OnInit {
	private ngUnsubscribe = new Subject();
	@Input() id: string;
	private element: any;

	panelClass: string | string[] = "custom-date-picker-modal";
	registroForm: FormGroup;
	loading: boolean;
	show: boolean;
	show2: boolean;
	campos;
	isLoading = true;
	modal = false;
	showPasswordInput = false;
	showPasswordInputModal = false;

	showConfirmationPasswordInputModal = false;
	showConfirmationPasswordInput = false;
	verificarRegistro = 0;
	paises = [];
	provincias = [];

	paisesV2 = [];
	provinciasV2 = [];
	ciudadesV2 = [];
	lastSelectedCodigoPais = null;
	lastSelectedProvincia = "";
	tiposClientes = [];

	bornDateSelected: string;
	webLoaded = false;

	constructor(
		private formBuilder: FormBuilder,
		private userService: UserService,
		private toast: ToastrService,
		private translateService: TranslateService,
		private productosService: ProductosService,
		private tokenManager: TokenManagerService,
		private ventaService: VentaService,
		private el: ElementRef,
		private modalService: ModalService,
		private global: GlobalService,
		private initService: InitService
	) {
		this.element = el.nativeElement;

		if (this.userService.currentUserValue) {
			//this.router.navigate(['/shop']);
		}
	}
	ngOnDestroy(): void {
		this.modalService.remove(this.id);
		this.element.remove();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.initService.cargado$
			.pipe(
				takeUntil(this.ngUnsubscribe),
				takeWhile(() => !this.webLoaded)
			)
			.subscribe((cargado) => {
				this.webLoaded = cargado;
				if (cargado) {
					this.initData();
				}
			});
	}

	initData() {

		forkJoin([
			this.productosService.GetPaisesV2(),
		]).subscribe(([paises]) => {
			if (paises?.DatosPaises && paises?.DatosPaises.length > 0) {
				const paisesResponse = paises.DatosPaises.filter(
					(p) => parseInt(p.Seleccionable) === 1
				);
				this.paisesV2 = paisesResponse.sort(
					(a, b) =>
						parseInt(a.OrdenVisualizacion) - parseInt(b.OrdenVisualizacion)
				);
			}
			this.show = false;
			this.cargarCamposWait();
			const modal = this;
			if (!this.id) {
				return;
			}

			document.body.appendChild(this.element);
			// tslint:disable-next-line:only-arrow-functions
			this.element.addEventListener("click", function (e: any) {
				if (e.target.className === "jw-modal") {
					modal.close();
				}
			});
			this.modalService.add(this);
		});
	}


	loadProvinciasV2(paisId: string) {
		const idioma = this.translateService.getLocale();
		const body: HttpBodyGetProvincias = {
			Idioma: idioma,
			idPais: paisId,
			Filtro: "provincia",
		};
		this.productosService.GetProvinciasV2(body).subscribe(
			(res) => {
				if (
					res?.DatosResult?.DatosCiudades &&
					res?.DatosResult?.DatosCiudades.length > 0
				) {
					const provinciasResponse = res.DatosResult.DatosCiudades.filter(
						(p) => parseInt(p.Seleccionable) === 1
					);
					this.provinciasV2 = provinciasResponse.sort(
						(a, b) =>
							parseInt(a.OrdenVisualizacion) - parseInt(b.OrdenVisualizacion)
					);

					const firstProvincia = this.provinciasV2[0];

					if (firstProvincia && this.existCampo("provincia")) {
						this.registroForm.get("items").get("provincia").setValue(firstProvincia.CodProvincia);
						this.lastSelectedProvincia = firstProvincia.CodProvincia;
					}

				} else {
					this.provinciasV2 = [];
					if (this.existCampo("provincia")) {
						this.registroForm.get("items").get("provincia").setValue("");
					}
				}
			},
			(error) => {
				this.provinciasV2 = [];
			}
		);
	}

	loadCiudadesV2(paisId: string, codProvincia: string) {
		const idioma = this.translateService.getLocale();
		const body: HttpBodyGetProvincias = {
			Idioma: idioma,
			idPais: paisId,
			CodProvincia: codProvincia,
			Filtro: "ciudad",
		};
		this.productosService.GetProvinciasV2(body).subscribe(
			(res) => {
				if (
					res?.DatosResult?.DatosCiudades &&
					res?.DatosResult?.DatosCiudades.length > 0
				) {
					const ciudadesResponse = res?.DatosResult?.DatosCiudades.filter(
						(p) => parseInt(p.Seleccionable) === 1
					);
					this.ciudadesV2 = ciudadesResponse.sort(
						(a, b) =>
							parseInt(a.OrdenVisualizacion) - parseInt(b.OrdenVisualizacion)
					);
				} else {
					this.ciudadesV2 = [];
				}
			},
			(error) => {
				this.ciudadesV2 = [];
			}
		);
	}

	toggleShowPasswordInput(isModal: Boolean = false) {
		if (isModal) {
			this.showPasswordInputModal = !this.showPasswordInputModal;
		} else {
			this.showPasswordInput = !this.showPasswordInput;
		}
	}

	toggleShowConfirmationPasswordInput(isModal: Boolean = false) {
		if (isModal) {
			this.showConfirmationPasswordInputModal =
				!this.showConfirmationPasswordInputModal;
		} else {
			this.showConfirmationPasswordInput = !this.showConfirmationPasswordInput;
		}
	}

	//Arroja de tipo moment object
	onChangeDate(e: MatDatepickerInputEvent<Moment>) {
		console.log({
			event: e.value,
			formatDate: e.value.format("YYYY-MM-DD"),
		});

		this.bornDateSelected = e.value.format("DD/MM/YYYY");
	}

	//La fecha máxima es el día actual
	getMaxDate(): Date {
		return new Date();
	}

	open(): void {
		this.element.style.display = "block";
		this.modal = true;
		document.body.classList.add("jw-modal-open");
	}
	close(): void {
		this.element.style.display = "none";
		this.modal = false;
		document.body.classList.remove("jw-modal-open");
	}

	cargarCamposWait() {
		this.ventaService.enlaceMenuCarrito$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((em) => {
				if (em) {
					if (this.productosService.getEnlaceMenuId()) {
						this.verificarRegistro =
							this.productosService.getEnlaceMenuId().iSolicitaVerificarRegistro;
						this.cargarCamposDinamicos(this.productosService.getEnlaceMenuId());
					}
				}
			});
	}

	existCampo(campo: string) {
		if (this.registroForm.get("items").get(campo)) {
			return true;
		}
		return false;
	}

	listenerPais() {
		if (this.existCampo("codigoPais")) {
			this.registroForm.get("items").get("codigoPais").valueChanges
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((pais) => {

					const paisFind = this.paisesV2.find((p) => p.idPais == pais);

					this.provinciasV2 = [];
					this.ciudadesV2 = [];

					if (this.existCampo("provincia")) {
						this.registroForm.get("items").get("provincia").setValue("");
					}

					if (this.existCampo("ciudad")) {
						this.registroForm.get("items").get("ciudad").setValue("");
					}

					if (paisFind) {
						this.registroForm.get("items").get("pais").setValue(paisFind.NombrePais);
						this.loadProvinciasV2(paisFind.idPais);
					}

				});
		}
	}

	listenerProvincia() {
		if (this.registroForm.get("items").get("provincia")) {
			this.registroForm.get("items").get("provincia").valueChanges
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((provincia) => {
					if (this.provinciasV2.length > 0) {
						//Si la provincia seleccionada es la misma que la última seleccionada, no se vuelve a hacer la petición
						if (provincia && provincia === this.lastSelectedProvincia) {
							return;
						}

						const pais = this.existCampo("codigoPais") ? this.registroForm.get("items").get("codigoPais").value : null;

						if (provincia && pais) {
							this.loadCiudadesV2(pais, provincia);
						}
					}
				});
		}
	}

	loadDefaultValues() {
		if (this.existCampo("codigoPais")) {
			console.log('loadDefaultValues');
			const firstPais = this.paisesV2[0];

			console.log({
				firstPais,
				paises: this.paisesV2,
			})
			if (firstPais) {
				this.registroForm.get("items").get("codigoPais").setValue(firstPais.idPais);
			}
		}
	}

	cargarCamposDinamicos(enlaceMenu) {
		if (
			enlaceMenu &&
			enlaceMenu.CaracteristicasAdicionales &&
			JSON.parse(enlaceMenu.CaracteristicasAdicionales) &&
			JSON.parse(enlaceMenu.CaracteristicasAdicionales)
				.CaracteristicasAdicionales &&
			JSON.parse(enlaceMenu.CaracteristicasAdicionales)
				.CaracteristicasAdicionales.DatosRegistro
		) {
			this.campos = JSON.parse(
				enlaceMenu.CaracteristicasAdicionales
			).CaracteristicasAdicionales.DatosRegistro;

			console.log({
				campos: this.campos,
			});

			// Los campos "poblacion", "ciudad" y "localidad" son lo mismo
			// Solo se manejará el campo ciudad
			if (this.campos && this.campos.length > 0) {
				this.campos = this.campos.map(
					(c) => {
						if (c.name === "poblacion" || c.name === "localidad") {
							c.name = "ciudad";
						}
						return c;
					}
				);

				// Dejar solo un campo con el nombre "ciudad"
				this.campos = this.campos.filter(
					(c, index, self) =>
						index === self.findIndex((t) => t.name === c.name)
				);
			}


			this.registroForm = this.formBuilder.group({
				items: this.createFormItems(this.campos),
				privacidad: [false, [Validators.requiredTrue]],
				publicidad: [false],
				recaptchaReactive: [null, Validators.required],
			});

			this.listenerPais();
			this.listenerProvincia();

			this.loadDefaultValues();

		}

		const a = this.getControls(this.registroForm);
		if (a) {
			const controls: any = a.controls;
			if (controls.tipodocumento) {
				controls.tipodocumento.valueChanges
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe((checked) => {
						console.log('tipodocumento::',checked);
						if (checked) {
							const validators = this.getValidatorsOnChangeTipodocumento(controls.tipodocumento.value);
							controls.documento.setValidators(validators);
						}
					});
			}
		}
	}

	getValidatorsOnChangeTipodocumento(tipoDocumentoValue: string = "") {
		let documentoCampoConfig = null;

		const documentoFind = this.campos.find((c) => c.name === "documento");

		if (documentoFind) {
			documentoCampoConfig = documentoFind;
		}

		if (documentoCampoConfig) {
			if (documentoCampoConfig.visible) {
				return [
					Validators.pattern(this.documentoValidar(tipoDocumentoValue)),
					this.ValidarDocNieDni("tipodocumento"),
					documentoCampoConfig.required ? Validators.required : Validators.nullValidator,
				];
			} else {
				return [Validators.nullValidator];
			}
		}

		return [Validators.nullValidator];

	}

	getControls(datosForm: FormGroup) {
		if (datosForm) {
			return <FormArray>datosForm.controls.items;
		}
	}

	documentoValidar(tipoDocumento: string) {
		const control = tipoDocumento;
		const nifReg = /^(\d{8})([A-Z]|[a-z])$/;
		const nieReg = /^[XYZ][0-9]{7}[A-Z]$/;
		if (control === "NIE") {
			return nieReg;
		}
		if (control === "DNI") {
			return nifReg;
		}
		if (control === "Otro") {
			return null;
		}
	}
	createFormItems(campos): FormGroup {
		//const cliente = this.ventaService.clienteValue;
		const group = {};

		if (campos) {
			for (const campo of campos) {
				if (campo.visible) {
					campo["password"] = false;
					switch (campo.name) {
						case "tipocliente":
							if (this.tiposClientes.length === 0) {
								this.productosService
									.GetTipoClientes(this.translateService.getLocale())
									.subscribe((res) => {
										this.tiposClientes = res.DatosListas ? res.DatosListas : [];
										campo.options = res.DatosListas ? res.DatosListas : [];
									});
							} else {
								campo.options = this.tiposClientes;
							}
							group[campo.name] = new FormControl(
								this.productosService.getEnlaceMenuId()
									? this.productosService.getEnlaceMenuId().chTiposClientes
									: "1",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "razonsocial":
							group[campo.name] = new FormControl(
								"",
								campo.required
									? [Validators.required, Validators.pattern("^[A-zÀ-ÿ.\\s]*$")]
									: Validators.pattern("^[A-zÀ-ÿ.\\s]*$")
							);
							break;
						case "nombreresponsable":
							group[campo.name] = new FormControl(
								"",
								campo.required
									? [Validators.required, Validators.pattern("^[A-zÀ-ÿ\\s]*$")]
									: Validators.pattern("^[A-zÀ-ÿ\\s]*$")
							);
							break;
						case "nombre":
							group[campo.name] = new FormControl(
								"",
								campo.required
									? [Validators.required, Validators.pattern("^[A-zÀ-ÿ\\s]*$")]
									: Validators.pattern("^[A-zÀ-ÿ\\s]*$")
							);
							break;
						case "apellidos":
							group[campo.name] = new FormControl(
								"",
								campo.required
									? [Validators.required, Validators.pattern("^[A-zÀ-ÿ\\s]*$")]
									: Validators.pattern("^[A-zÀ-ÿ\\s]*$")
							);
							break;
						case "apellido2contacto":
							group[campo.name] = new FormControl(
								"",
								campo.required
									? [Validators.required, Validators.pattern("^[A-zÀ-ÿ\\s]*$")]
									: Validators.pattern("^[A-zÀ-ÿ\\s]*$")
							);
							break;
						case "tipodocumento":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "documento":
							group[campo.name] = new FormControl(
								"",
								campo.required
									? [
											Validators.required,
											this.ValidarDocNieDni("tipodocumento"),
									  ]
									: [
											Validators.nullValidator,
											this.ValidarDocNieDni("tipodocumento"),
									  ]
							);
							break;
						case "sexo":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "emailconfirmacion":
							group[campo.name] = new FormControl(
								"",
								campo.required
									? [
											Validators.required,
											Validators.pattern(
												"^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
											),
											this.repetirValidar("email"),
									  ]
									: [
											Validators.pattern(
												"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-_]+.[a-zA-Z]{2,3}$"
											),
											this.repetirValidar("email"),
									  ]
							);
							break;
						case "email":
							group[campo.name] = new FormControl(
								"",
								campo.required
									? [
											Validators.required,
											Validators.pattern(
												"^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
											),
									  ]
									: Validators.pattern(
											"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-_]+.[a-zA-Z]{2,3}$"
									  )
							);
							break;
						case "password":
							campo["password"] = true;
							campo["type"] = "input";
							campo["inputType"] = "password";
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "passwordconfirmacion":
							campo["password"] = true;
							campo["type"] = "input";
							campo["inputType"] = "password";

							group[campo.name] = new FormControl(
								"",
								campo.required
									? [Validators.required, this.repetirValidar("password")]
									: [Validators.nullValidator, this.repetirValidar("password")]
							);
							break;
						case "direccion":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;

						case "ciudad":
							group["ciudad"] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "codigopostal":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "provincia":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "pais":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);

							group["codigoPais"] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);

							// this.paisSeleccionado = firstPais ? firstPais.idPais : "";
							break;

						case "telefono":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "publicidad":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "notas":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "notasinternas":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "tipocentro":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						case "curso":
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
						default:
							group[campo.name] = new FormControl(
								"",
								campo.required ? Validators.required : Validators.nullValidator
							);
							break;
					}
				}
			}
		}

		const apiFormGroup = new FormGroup(group);

		this.isLoading = false;
		return apiFormGroup;
	}

	repetirValidar(otherControlName: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const a = control.root["controls"];
			let otherControl: AbstractControl;
			if (a) {
				otherControl = a.items.get(otherControlName);
			}
			if (otherControl) {
				const subscription: Subscription = otherControl.valueChanges.subscribe(
					() => {
						control.updateValueAndValidity();
						subscription.unsubscribe();
					}
				);
			}
			return otherControl && control.value !== otherControl.value
				? { match: true }
				: null;
		};
	}
	ValidarDocNieDni(otherControlName: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const a = control.root["controls"];
			let otherControl: AbstractControl;
			if (a) {
				otherControl = a.items.get(otherControlName);
			}
			if (otherControl) {
				const subscription: Subscription = otherControl.valueChanges.subscribe(
					() => {
						control.updateValueAndValidity();
						subscription.unsubscribe();
					}
				);
			}

			let documento = control
				? control.value == undefined
					? ""
					: control.value
				: "";
			let tipoDocumento = otherControl
				? otherControl.value == undefined
					? ""
					: otherControl.value
				: "";
			return !this.ValidarDocumento(tipoDocumento, documento)
				? { match: true }
				: null;
		};
	}

	ValidarDocumento(tipoDocumento: string, numeroDocumento: string) {
		let numValido = true;
		const control = tipoDocumento;
		if (control === "NIE") {
			numValido = this.global.VerificarNIE(numeroDocumento);
		}
		if (control === "DNI") {
			numValido = this.global.VerificarDNI(numeroDocumento);
		}
		if (control === "CIF") {
			numValido = this.global.VerificarDNI(numeroDocumento);
		}
		return numValido;
	}

	campoTipo(chTipoControl: string) {
		switch (chTipoControl) {
			case "radiobutton":
				return "dropDown";
			case "select":
				return "dropDown";
			case "input":
				return "text";
			case "password":
				return "password";
			default:
				return chTipoControl;
		}
	}
	nombreCampo(nombreCampo) {
		switch (nombreCampo.toLowerCase()) {
			case "nombrecentro":
				return this.translateService.data.NOMBRECENTRO;
			case "nombre":
				return this.translateService.data.NOMBRE;
			case "nombreresponsable":
				return this.translateService.data.NOMBRERESPONSABLE;
			case "curso":
				return this.translateService.data.CURSO;
			case "tipocentro":
				return this.translateService.data.TIPOCENTRO;
			case "email":
				return this.translateService.data.EMAIL;
			case "codigopostal":
				return this.translateService.data.CODIGOPOSTALR;
			case "tipodocumento":
				return this.translateService.data.TIPODOCUMENTO;
			case "documento":
				return this.translateService.data.DOCUMENTO;
			case "cifodni":
				return this.translateService.data.DOCUMENTO;
			case "telefono":
				return this.translateService.data.TELEFONO;
			case "telefonomovil":
				return this.translateService.data.TELEFONOMOVIL;
			case "direccion":
				return this.translateService.data.DIRECCION;
			case "ciudad":
				return this.translateService.data.CIUDAD;
			case "notas":
				return this.translateService.data.NOTAS;
			case "notasinternas":
				return this.translateService.data.NOTASINTERNAS;
			case "pais":
				return this.translateService.data.PAIS;
			case "provincia":
				return this.translateService.data.PROVINCIA;
			case "nombrecontacto":
				return this.translateService.data.NOMBRECONTACTO;
			case "apellidos":
				return this.translateService.data.APELLIDO1;
			case "apellido2contacto":
				return this.translateService.data.APELLIDO2;
			case "sexo":
				return this.translateService.data.SEXO;
			case "nacionalidad":
				return this.translateService.data.NACIONALIDAD;
			case "codigopais":
				return this.translateService.data.CODIGOPAIS;
			case "edad":
				return this.translateService.data.EDAD;
			case "fechanacimiento":
				return this.translateService.data.FECHANACIMIENTO;
			case "emailconfirmacion":
				return this.translateService.data.EMAILCONFIRMACION;
			case "passwordconfirmacion":
				return this.translateService.data.PASSWORDCONFIRMACION;
			case "tipocliente":
				return this.translateService.data.TIPOCLIENTE;
			case "password":
				return this.translateService.data.CONTRASENA;
			default:
				return nombreCampo;
		}
	}
	get f() {
		return this.registroForm.controls;
	}

	showForm() {}

	registroClicked() {
		const param = 1;
		this.loading = true;

		if (this.registroForm.invalid) {
			//Marcar en rojo los campos inválidos
			this.registroForm.markAllAsTouched();
			this.loading = false;
			return;
		}

		let userPayload = this.registroForm.get("items").value;

		if (this.provinciasV2.length > 0) {
			const provincia = this.provinciasV2.find(
				(p) => p.CodProvincia === userPayload.provincia
			);
			userPayload.Provincia = provincia ? provincia.NombreProvincia : "";
		} else {
			userPayload.Provincia = userPayload.provincia;
		}

		if (this.ciudadesV2.length > 0) {
			const ciudad = this.ciudadesV2.find(
				(p) => p.CodCiudad === userPayload.ciudad
			);
			userPayload.Localidad = ciudad ? ciudad.NombreCiudad : "";
		} else {
			userPayload.Localidad = userPayload.ciudad;
		}

		if (this.paisesV2.length > 0) {
			const pais = this.paisesV2.find(
				(p) => p.idPais === userPayload.codigoPais
			);
			userPayload.CodigoPais = pais ? pais.CodPais : "";
		}

		this.userService
			.registro(
				userPayload,
				param,
				"signup",
				this.registroForm.get("publicidad").value,
				this.translateService.getLocale(),
				this.verificarRegistro
			)
			.subscribe((res) => {
				if (res.DatosResult.Avisos != null) {
					// this.router.navigate(['/account/login']);
					if (this.verificarRegistro == 1) {
						this.toast.success(
							this.translateService.data.SolicitudEnProceso,
							"",
							{
								timeOut: 5000,
								positionClass: "toast-top-right",
							}
						);
						if (this.modal) {
							this.registroForm.reset();
							this.close();
						}
						this.modalService.close("LoginAndRegister");
					} else {
						this.toast.success(
							this.translateService.data.registradoCorrectamente,
							"",
							{
								timeOut: 3000,
								positionClass: "toast-top-right",
							}
						);
						this.login();
					}
				} else {
					this.toast.error(
						this.userService.getErrorTranslate(
							res["Mensajes"][0]["CodigoMensaje"]
						),
						"Error",
						{
							timeOut: 3000,
							positionClass: "toast-top-right",
						}
					);
				}
				this.loading = false;
			});
	}

	login() {
		this.userService
			.login(this.registroForm.get("items").value, 1, 1, true)
			.subscribe(
				(res) => {
					if (res && res.DatosResult) {
						if (
							this.productosService.getEnlaceMenuId().chTiposClientes ==
							res.DatosResult.TipoClienteId
						) {
							this.userService.setDatosUserValue(res.DatosResult);
							this.ventaService.mapCliente(res.DatosResult);
							this.ventaService.setEnlaceMenuCarrito(
								this.ventaService.enlaceMenuCarritoValue
							);
							if (this.modal) {
								this.close();
							}
							this.modalService.close("LoginAndRegister");
							/* this.userService.setDatosUserValue(res.DatosResult)
          this.ventaService.mapCliente(res.DatosResult);
          this.ventaService.setEnlaceMenuCarrito(this.ventaService.enlaceMenuCarritoValue) */
							// this.router.navigate(['/tienda/producto/1']);
						} else {
							this.toast.error(
								this.translateService.data.usarioNoCorresponde,
								"",
								{
									timeOut: 3000,
									positionClass: "toast-top-right",
								}
							);
							this.userService.logout(false);
						}
					} else {
						this.toast.error(
							this.userService.getErrorTranslateLogin(
								res.Mensajes[0]["CodigoMensaje"]
							),
							"",
							{
								timeOut: 3000,
								positionClass: "toast-top-right",
							}
						);
					}
					this.loading = false;
				},
				(err) => {
					this.toast.error(this.translateService.data[err], "Error", {
						timeOut: 3000,
						positionClass: "toast-top-right",
					});
					this.loading = false;
				}
			);
	}
	password() {
		this.show = !this.show;
	}
	passwordValid() {
		this.show2 = !this.show2;
	}

	focusOut() {
		this.registroForm.markAllAsTouched();
	}
}
