import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { MatRadioChange } from "@angular/material/radio";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { take, takeUntil, takeWhile } from "rxjs/operators";
import { AppConfiguration } from "src/app/app.configuration";
import { ConfigurationService } from "src/app/core/config/configuration.service";
import { InitService } from "src/app/core/init/init.service";
import { PaymentService } from "src/app/core/payment/payment.service";
import { PaymentGateway } from "src/app/core/payment/paymentGateway.enum";
import { PaymentType } from "src/app/core/payment/paymentType.enum";
import { SesionService } from "src/app/core/services/sesion.service";
import { Compra } from "src/app/models/compra.model";
import { ModalService } from "src/app/shared/modal.service";
import { TranslateService } from "src/app/shared/translate.service";
import { UserService } from "src/app/user/user.service";
import { VentaService } from "../../core/services/venta.service";
import {
  DatosPublicidadType,
  caracteristicasAdicionalesType,
} from "../interfaces/configuraciones";
import { ProductosService } from "../productos.service";
import { CalendarService } from "../ticket-sales/pages/date-selector/calendar/calendar.service";
import { UtilsService } from "../utils.service";
import { ModelFormasPagoProyecto } from "./model-formas-pago-proyecto";
import { ProcedenciaValues } from "./interfaces/resume.interface";

@Component({
  selector: "app-resume",
  templateUrl: "./resume.component.html",
  styleUrls: ["./resume.component.scss"],
})
export class ResumeComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  proyecto = this.config.getProyecto();
  tiposDescuento = ["Porcentaje", "Importe"];
  compradorForm: FormGroup;
  paymentGatewayForm: FormGroup;
  datosFacturaForm: FormGroup;
  datosForm: FormGroup;
  mostrarModificarReserva: boolean;
  mostrarComprar: boolean;
  mostrarReservar: boolean;
  mostrarPhone: boolean;
  mostrarBizum: boolean;
  mostrarPaypal: boolean;
  tieneUser: boolean;
  noEsAdmin: boolean;
  mostrarFactura: boolean;
  cargado: boolean;
  check: boolean;
  paises: any = [];
  provincias: any = [];
  listaCarrito: any = [];
  paisDefecto: any;
  nombreUser: string;
  numPedido: string;
  datosUrl: string;
  loading: boolean;
  user;
  estadebaja = false;
  nosepuedereservar: boolean;
  internalpol: any;
  showClientDiscount: boolean = false;
  publicity: DatosPublicidadType[];
  strPublicity: string;
  errorPublicity: boolean = false;
  disabledSend: boolean = false;
  viewErrorRadio: boolean = false;
  showMessageErrors: boolean = false;
  txtMessageError: string;
  arrErrors: string[] = [];
  provinciaCliente: string;
  viewCaptcha: boolean;

  checkBlocked: boolean = false;

  procedenciaForm: ProcedenciaValues = {
    Pais: "",
    CodigoPais: "",
    ProvinciaContacto: "",
    CodProvincia: "",
    CodCiudad: "",
    Ciudad: "",
  };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private initService: InitService,
    private toast: ToastrService,
    private ventaService: VentaService,
    private paymentService: PaymentService,
    private productosService: ProductosService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private config: AppConfiguration,
    private translateService: TranslateService,
    private configurationService: ConfigurationService,
    private calendarService: CalendarService,
    private userService: UserService,
    private _sesionService: SesionService,
    private util: UtilsService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterContentInit() {
    //this.GAService.callStepView('resume');
  }

  ngOnInit() {
    this.productosService.setDataRegistro();
    this.translateService
      .use(this.translateService.getLocale(), this.config.getProyecto())
      .then((i) => {
        this.internalpol = i["CONFIG_POLITICAS_INTERNAS"];
      });

    this.ventaService.setNumCarrito();

    this.userService.currentUser$.subscribe((user) => {
      this.user = user;
      if (this.compradorForm) {
        this.compradorForm
          .get("PublicidadInterna")
          .setValue(
            this.user
              ? this.user.PublicidadInterna == "1"
                ? true
                : false
              : false
          );
      }
    });
    this.user = JSON.parse(sessionStorage.getItem("currentUser"));

    this.userService.currentUser$.subscribe((user) => {
      if (user && user.DeBaja == "1") {
        this.estadebaja = true;
      } else {
        this.estadebaja = false;
      }
    });

    this.productosService.setCargandoComponente2Value(true);
    this.resetValues();
    this.compradorForm = this.formBuilder.group({
      Privacidad: ["", [Validators.requiredTrue]],
      PublicidadInterna: [
        this.user ? (this.user.PublicidadInterna == "1" ? true : false) : false,
      ],
      recaptchaReactive: new FormControl(
        null,
        //Activar captcha
        this.noEsAdmin ? Validators.required : Validators.nullValidator,

         //Deshabilitar captcha
        // Validators.nullValidator
      ),
    });
    this.paymentGatewayForm = this.formBuilder.group({
      redsys: true,
      paypal: false,
      bizum: false,
      phoneAndSell: false,
    });

    this.ventaService.enlaceMenuCarrito$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((em) => {
        if (em) {
          const enlaceMenu = this.productosService.enlacesMenuValue.find(
            (e) => e.pkid === em
          );
          if (enlaceMenu) {
            const fields: caracteristicasAdicionalesType = JSON.parse(
              enlaceMenu.CaracteristicasAdicionales
            ).CaracteristicasAdicionales;
            this.publicity = fields.DatosPublicidad
              ? fields.DatosPublicidad
              : this.publicity;
            const isRequired = this.publicity?.find(
              (pub) =>
                pub.inputType === "radiobutton" && pub.visible && pub.required
            );
            this.errorPublicity = isRequired ? true : false;
            if (this.errorPublicity) {
              this.compradorForm.addControl(
                "fakePublicity",
                new FormControl("", Validators.required)
              );
              let textTranslate = this.translateService.data.PUBLICIDAD;
              this.strPublicity =
                textTranslate.substring(0, textTranslate.length - 1) + ":";
            }
          }
        }
      });

    this.productosService.cargandoComponente2$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((carga) => (this.loading = carga));
    this.paymentGatewayForm.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
    this.cargarCarrito();
  }

  private mostrarCaptcha = (): boolean => {
    this.viewCaptcha = true;
    if (sessionStorage.getItem("admin") && !this.noEsAdmin) {
      const admin = JSON.parse(sessionStorage.getItem("admin"));
      if (admin.ua) {
        this.viewCaptcha = false;
      }
    }
    return this.viewCaptcha;
  };

  cargarCarrito() {
    // manieva - independientemente desde donde cargue el carrito
    // pauso la recuperacion de data
    //this.timerService.resume();
    // manieva - timer

    // carga carrito desde traerReserva
    if (this.route.snapshot.queryParams.ck) {
      this.datosUrl = this.route.snapshot.queryParams.ck;
      sessionStorage.setItem(
        "admin",
        this.initService.funcionDesencript(this.datosUrl)
      );
      let currentuser = JSON.parse(sessionStorage.getItem("currentUser"));
      if (!currentuser) {
        this.initService.setFromAdmonValue(true);
      }
      if (
        JSON.parse(this.initService.funcionDesencript(this.datosUrl)).numpedmod
      ) {
        this.ventaService.setModificandoReserva(
          JSON.parse(this.initService.funcionDesencript(this.datosUrl))
            .numpedmod
        );
      }
      this.cargarCarritoTraerReserva();
      console.log('cargarCarritoTraerReserva');
      console.log(this.initService.funcionDesencript(this.datosUrl))
      // carga carrito en venta online
    } else {
      if (!this.initService.startedValue) {
        this.initService.traerDatosInicio(false);
      }
      if (!this.ventaService.carritoValue.compras.length) {
        this.ventaService.cargarCesta();
        this.ventaService.cargarFechaSeleccionadasSinSesion();
      }

      // Para escuela productos bono varias personas
      if (this.ventaService.carritoValue.compras.length) {
        /*   
        if(this.ventaService.carritoValue.compras.length>0){
         // this.ventaService.setFechaSeleccionadaValue(moment(this.ventaService.carritoValue.compras[this.ventaService.carritoValue.compras.length-1].sesionesSeleccionadas[0].Fecha))
        } */
        console.log({
          message: 'Cargar carrito wihout ck',
          carrito: this.ventaService.carritoValue,
          modificarReserva: this.ventaService.modificandoReservaValue,
        });
        this.ventaService.carritoValue.compras.forEach((producto) => {
          Object.keys(producto.producto).map(() => {
            producto.producto.datos = producto.datos;
          });
        });
        this.initService.cargado$
          .pipe(
            takeUntil(this.ngUnsubscribe),
            takeWhile(() => !this.cargado)
          )
          .subscribe((cargado) => {
            this.cargado = cargado;
            if (this.cargado) {
              // this.cargarFiltros();
              if (this.ventaService.carritoValue.compras.length > 0) {
                console.log({
                  carritoWithoutCK: this.ventaService.carritoValue
                })
                const reservaPagada = this.ventaService.carritoValue.compras[0].producto.ReservaPagada
                  ? this.ventaService.carritoValue.compras[0].producto.ReservaPagada
                  : false;
                
                const modificarReservaValue = this.ventaService.carritoValue.compras[0].producto.isModificarReserva
                  ? this.ventaService.carritoValue.compras[0].producto.isModificarReserva
                  : "";

                this.ventaService.setModificandoReserva(modificarReservaValue);
                this.ventaService.setIsReservaPagadaValue(reservaPagada);

                const em = this.ventaService.carritoValue.compras[0].producto
                  .enlaceMenu
                  ? this.ventaService.carritoValue.compras[0].producto
                      .enlaceMenu
                  : this.productosService.getEnlaceMenuId();
                const pkid = em.pkid ? em.pkid : em;
                this.ventaService.setEnlaceMenuCarrito(pkid);
                const enlaceMenu = this.productosService.enlacesMenuValue.find(
                  (e) => e.pkid === pkid
                );
                this.cargarUsuario();
                this.cargarOpcionesCarrito(enlaceMenu);
                if (!sessionStorage.getItem("token")) {
                  this.productosService.setDatosInitValue({
                    empresa: this.proyecto,
                    m: enlaceMenu.chNombre,
                  });
                }
              }
            }
          });
        this.ordenarCarrito();
        this.productosService.setCargandoComponente2Value(false);

        // revisamos los límites de las reservas del carrito para actualizar la variable nosepuedereservar y ocultar el boton Reserva
        this.nosepuedereservar = this._sesionService.validaReservas(
          this.listaCarrito,
          this.ventaService.fechaSeleccionadaSinSessionValue
        );
      }
      this.productosService.setCargandoComponente2Value(false);
    }


    this.mostrarCaptcha();

    //Deshabilitar captcha
    // this.viewCaptcha = false;
  }

  resetValues() {
    this.ventaService.setFechaSeleccionadaValue(moment());
    this.calendarService.currentDate = moment();
    sessionStorage.removeItem("descuento");
    sessionStorage.removeItem("factura");
    sessionStorage.removeItem("pedido");
    sessionStorage.removeItem("localizador");
    sessionStorage.removeItem("payment");

    this.ventaService.setIsReservaPagadaValue(false);

    this.ventaService.setCompra(new Compra());
    this.ventaService.sesionesReservadas = [];
    this.ventaService.setDescuentoObjeto(undefined);
    this.ventaService.setDescuentoCod(undefined);
    this.ventaService.setDescuento(undefined);
    this.ventaService.setDescuentoPer(undefined);
    this.ventaService.setDescuentoPersonalizado(undefined);
    this.ventaService.setDescuentoTotales(undefined);
    this.ventaService.setDescuentoClienteAsociado(undefined);
    this.ventaService.setDescuentoClienteAsociadoCod(undefined);
    this.ventaService.setDescuentoClienteAsociadoObjeto(undefined);
    this.productosService.setFiltroTipoValue("");
    this.productosService.setFiltroGrupoValue("");
    this.productosService.setFiltroNivelValue("");
    this.ventaService.setCambios(false);
    this.paymentService.setMostrarBotonesPagar(false);
    this.paymentService.setMostrarBotonesReservar(false);
    this.paymentService.setMostrarBotonesPhone(false);
    this.paymentService.setMostrarBotonesBizum(false);
    this.paymentService.setmostrarBotonesPaypal(false);
    this.paymentService.setMostrarDescuento(false);
    this.paymentService.setPaymentGateway(PaymentGateway.REDSYS);
    sessionStorage.setItem("payment", JSON.stringify(PaymentGateway.REDSYS));
  }

  get f() {
    return this.compradorForm.controls;
  }

  receiveDatosCliente($event) {
    this.datosForm = $event;
  }

  receiveDatosProcedencia($event) {
    this.procedenciaForm = $event;
  }

  receiveDatosFactura($event) {
    this.datosFacturaForm = $event;
  }

  receiveThisCheck($event) {
    this.check = $event;
    this.paymentService.conFactura = this.check ? 1 : 0;
  }

  public changePublicity = ($event: MatRadioChange): void => {
    const response = $event.value === "SI" ? true : false;
    this.compradorForm.get("PublicidadInterna").setValue(response);
    this.errorPublicity = false;
    this.viewErrorRadio = false;
    this.disabledSend = false;
  };

  clickRadio(evt, value) {
    this.paymentGatewayForm.reset();
    this.paymentGatewayForm.get(value).patchValue(value);
    switch (value) {
      case "redsys":
        this.paymentService.setPaymentGateway(PaymentGateway.REDSYS);
        sessionStorage.setItem(
          "payment",
          JSON.stringify(PaymentGateway.REDSYS)
        );
        break;
      case "paypal":
        this.paymentService.setPaymentGateway(PaymentGateway.PAYPAL);
        sessionStorage.setItem(
          "payment",
          JSON.stringify(PaymentGateway.PAYPAL)
        );
        break;
      case "bizum":
        this.paymentService.setPaymentGateway(PaymentGateway.BIZUM);
        sessionStorage.setItem("payment", JSON.stringify(PaymentGateway.BIZUM));
        break;
    }
  }

  private findErrorsFields = (): void => {
    Object.keys(this.datosForm.controls.items["controls"]).forEach(
      (control: string) => {
        let status = this.datosForm.get("items").get(control).status;
        if (status === "INVALID") {
          const name = this.util.getPlaceHolder(control);
          this.arrErrors = [...this.arrErrors, name];
        }
      }
    );

    Object.keys(this.compradorForm.controls).forEach((control: string) => {
      let status = this.compradorForm.get(control).status;
      if (status === "INVALID") {
        const name = this.util.getPlaceHolder(control);
        this.arrErrors = [...this.arrErrors, name];
      }
    });
    if (this.check === true) {
      Object.keys(this.datosFacturaForm.controls).forEach((control: string) => {
        let status = this.datosFacturaForm.get(control).status;
        if (status === "INVALID") {
          const name = this.util.getPlaceHolder(control);
          this.arrErrors = [...this.arrErrors, name];
        }
      });
    }
  };

  private showErrors = (): void => {
    this.findErrorsFields();
    if (this.arrErrors.length === 0) {
      return;
    }
    this.showMessageErrors = true;
    this.txtMessageError = "";
    this.txtMessageError =
      this.arrErrors.length > 1
        ? this.translateService.data.MSG_ERROR_PLURAL
        : this.translateService.data.MSG_ERROR_SINGULAR;
  };

  insercionVenta(tipoVenta: PaymentType) {
    this.arrErrors = [];
    if (
      this.errorPublicity &&
      this.compradorForm.controls["fakePublicity"].errors !== null
    ) {
      this.viewErrorRadio = true;
      this.disabledSend = true;
      this.showErrors();
      return;
    } else if (
      this.datosForm.invalid ||
      (this.noEsAdmin && this.compradorForm.invalid) ||
      (this.check && this.datosFacturaForm.invalid) ||
      this.estadebaja ||
      (this.showClientDiscount && this.ventaService.discount)
    ) {
      if (this.showClientDiscount && this.ventaService.discount) {
        this.arrErrors = [
          ...this.arrErrors,
          this.translateService.data.CAMPO_DOCUMENTO_ASEGURADO,
        ];
      }
      this.showErrors();
      this.disabledSend = true;
      return;
    } else {
      this.disabledSend = false;
    }
    this.productosService.setCargandoValue(true);
    const cliente = {
      ...this.ventaService.clienteValue,
      ...this.compradorForm.value,
      procedenciaUser: this.procedenciaForm,
    };
    cliente["Ciudad"] = this.datosForm.value.items.localidad
      ? this.datosForm.value.items.localidad
      : this.datosForm.value.items.ciudad
      ? this.datosForm.value.items.ciudad
      : "";

    //11401 el nombreContacto se pisaba
    if (
      cliente["NombreContacto"] == "" ||
      cliente["NombreContacto"] == undefined
    )
      cliente["NombreContacto"] = this.datosForm.value.items.nombre
        ? this.datosForm.value.items.nombre
        : this.datosForm.value.items.Nombrecontacto
        ? this.datosForm.value.items.Nombrecontacto
        : "";

    if (cliente["NombreCliente"] == "" || cliente["NombreCliente"] == undefined)
      cliente["NombreCliente"] = this.datosForm.value.items.Nombrecontacto;

    this.ventaService.setCliente(cliente);
    //return;
    if (this.check) {
      sessionStorage.setItem(
        "factura",
        JSON.stringify(this.datosFacturaForm.value)
      );
    } else {
      sessionStorage.removeItem("factura");
    }

    this.userService.changeSessionData(this.compradorForm.value);
    if (this.paymentService.validarCampos(cliente)) {
      switch (tipoVenta) {
        case PaymentType.PAGO:
          this.paymentService.setPaymentType(PaymentType.PAGO);
          this.paymentService
            .pagarInsercionUnica()
            .pipe(take(1))
            .subscribe(() =>
              setTimeout(() => {
                this.productosService.setCargandoValue(false);
              }, 5000)
            );
          return;
        case PaymentType.RESERVA:
          this.paymentService.setPaymentType(PaymentType.RESERVA);
          this.paymentService
            .reservarInsercionUnica()
            .pipe(take(1))
            .subscribe(() => this.productosService.setCargandoValue(false));
          return;
        case PaymentType.MODIFICARRESERVA:
          this.paymentService.setPaymentType(PaymentType.MODIFICARRESERVA);
          this.paymentService
            .modificarReservaUnica()
            .pipe(take(1))
            .subscribe(() => this.productosService.setCargandoValue(false));
          return;
        case PaymentType.PHONEANDSELL:
          this.paymentService.setPaymentType(PaymentType.PHONEANDSELL);
          if (
            this.ventaService.carritoValue.calcularPrecioTotal() == 0 ||
            (this.ventaService.descuentoTotalesValue &&
              this.ventaService.descuentoTotalesValue.totalDescuento !=
                undefined &&
              this.ventaService.descuentoTotalesValue.totalDescuento == 0)
          ) {
            sessionStorage.setItem(
              "payment",
              JSON.stringify(PaymentGateway.PHONEANDSELL)
            );
            this.paymentService
              .phoneNsellInsercionUnica()
              .pipe(take(1))
              .subscribe((res) => {});
          } else {
            this.modalService.open("phoneSell");
          }
          this.productosService.setCargandoValue(false);

          return;
      }
    } else {
      this.productosService.setCargandoValue(false);
      this.toast.error(this.translateService.data.VERIFICAREMAIL, "Error", {
        timeOut: 5000,
        positionClass: "toast-top-right",
      });
    }
  }

  cargarFiltros() {
    this.productosService
      .GetPaises(this.translateService.getLocale())
      .pipe(take(1))
      .subscribe((res) => {
        res.DatosListas.forEach((element) => {
          this.paises.push(element);
        });
        if (
          this.ventaService.clienteValue &&
          this.ventaService.clienteValue.Pais
        ) {
          this.paisDefecto = this.paises.find(
            (pais) =>
              pais.Nombre.toUpperCase() ===
              this.ventaService.clienteValue.Pais.toUpperCase()
          );
        } else {
          this.paisDefecto = this.paises.find(
            (pais) => pais.Nombre === "España"
          )
            ? this.paises.find((pais) => pais.Nombre === "España")
            : "";
        }
        if (this.datosFacturaForm) {
          this.datosFacturaForm.get("pais").setValue(this.paisDefecto?.Nombre);
          this.datosFacturaForm
            .get("codigoPais")
            .setValue(this.paisDefecto?.Id);
        }
        if (this.datosForm) {
          if (this.datosForm.get("items").get("pais")) {
            this.datosForm
              .get("items")
              .get("pais")
              .setValue(this.paisDefecto?.Nombre);
          }
          if (this.datosForm.get("items").get("codigoPais")) {
            this.datosForm
              .get("items")
              .get("codigoPais")
              .setValue(this.paisDefecto?.Id);
          }
        }
      });

    this.productosService
      .GetProvincias(this.translateService.getLocale())
      .pipe(take(1))
      .subscribe((res: any) => {
        this.provincias = res.DatosListas ? res.DatosListas : [];
        return;
      });
  }

  cargarUsuario() {
    if (JSON.parse(sessionStorage.getItem("admin"))) {
      if (JSON.parse(sessionStorage.getItem("admin")).u) {
        this.ventaService.cliente$
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((user) => {
            if (user) {
              this.nombreUser = this.ventaService.clienteValue.NombreContacto; //NOMBRE CLIENTE
              this.productosService.setTieneUserValue(true);
            }
          });
        this.productosService.tieneUser$
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((tiene) => (this.tieneUser = tiene));
      }
      if (this.ventaService.modificandoReservaValue) {
        this.numPedido = this.ventaService.modificandoReservaValue;
      }
    }
  }

  ordenarCarrito() {
    if (this.ventaService.carritoValue.compras.length !== 0) {
      this.listaCarrito = this.ventaService.ordenarCarrito();
    }
  }

  cargarOpcionesCarrito(enlaceMenu) {
    console.log({
      enlaceMenuOpcionCarrito: enlaceMenu,
    })

    this.noEsAdmin = this.productosService.noEsAdministracion();
    //FACTURA
    if (enlaceMenu.iSolicitaFactura) {
      if (enlaceMenu.iSolicitaFactura === "0") {
        this.paymentService.setMostrarFactura(false);
        this.paymentService.mostrarFactura$
          .pipe(take(1))
          .subscribe((mostrar) => (this.mostrarFactura = mostrar));
      } else if (enlaceMenu.iSolicitaFactura === "1") {
        sessionStorage.setItem("iSolicitaFactura", "1");
        this.checkBlocked = false;
        this.paymentService.setMostrarFactura(true);
        this.paymentService.mostrarFactura$
          .pipe(take(1))
          .subscribe((mostrar) => (this.mostrarFactura = mostrar));
      } else if (enlaceMenu.iSolicitaFactura === "4") {
        this.checkBlocked = false;
        this.paymentService.setMostrarFactura(true);
        this.paymentService.mostrarFactura$
          .pipe(take(1))
          .subscribe((mostrar) => (this.mostrarFactura = mostrar));
      } else if (enlaceMenu.iSolicitaFactura === "2") {
        this.check = true;
        this.checkBlocked = false;
        this.paymentService.setMostrarFactura(true);
        this.paymentService.mostrarFactura$
          .pipe(take(1))
          .subscribe((mostrar) => (this.mostrarFactura = mostrar));
      } else if (enlaceMenu.iSolicitaFactura === "3") {
        // Se muestra check de factura y se marca por defecto y se bloquea
        this.check = true;
        this.checkBlocked = true;
        sessionStorage.setItem("iSolicitaFactura", "3");
        this.paymentService.setMostrarFactura(true);
        this.paymentService.mostrarFactura$
          .pipe(take(1))
          .subscribe((mostrar) => (this.mostrarFactura = mostrar));
      } else if (enlaceMenu.iSolicitaFactura === "5") {
        // Se muestra check de factura y se marca por defecto y se bloquea
        this.check = true;
        this.checkBlocked = true;
        sessionStorage.setItem("iSolicitaFactura", "5");
        this.paymentService.setMostrarFactura(true);
        this.paymentService.mostrarFactura$
          .pipe(take(1))
          .subscribe((mostrar) => (this.mostrarFactura = mostrar));
      }
    }
    if (this.paymentService.tieneAdelantoValue) {
      this.paymentService.setMostrarFactura(false);
      this.paymentService.mostrarFactura$
        .pipe(take(1))
        .subscribe((mostrar) => (this.mostrarFactura = mostrar));
    }
    //DESCUENTOS
    if (
      JSON.parse(enlaceMenu.CaracteristicasAdicionales)
        .CaracteristicasAdicionales.AdmiteDtoPersonalizado
    ) {
      if (
        JSON.parse(enlaceMenu.CaracteristicasAdicionales)
          .CaracteristicasAdicionales.AdmiteDtoPersonalizado === "1" &&
        !this.noEsAdmin
      ) {
        this.paymentService.setMostrarDescuentoAdmin(true);
      }
    }
    if (
      JSON.parse(enlaceMenu.CaracteristicasAdicionales)
        .CaracteristicasAdicionales.CodigoClienteAsociado
    ) {
      if (
        JSON.parse(enlaceMenu.CaracteristicasAdicionales)
          .CaracteristicasAdicionales.CodigoClienteAsociado === "1"
      ) {
        this.paymentService.setMostrarDescuentoClienteAsociado(true);
        this.showClientDiscount = true;
      }
    }
    if (enlaceMenu.iVerCodigosPromocionales === "0") {
      this.paymentService.setMostrarDescuento(false);
      this.paymentService.setMostrarValePromocional(false);
    } else if (enlaceMenu.iVerCodigosPromocionales === "1") {
      this.paymentService.setMostrarDescuento(false);
      this.paymentService.setMostrarValePromocional(true);
    } else if (enlaceMenu.iVerCodigosPromocionales === "2") {
      this.paymentService.setMostrarDescuento(true);
      this.paymentService.setMostrarValePromocional(false);
    } else if (enlaceMenu.iVerCodigosPromocionales === "3") {
      this.paymentService.setMostrarDescuento(true);
      this.paymentService.setMostrarValePromocional(true);
    }
    // BOTONES
    if (this.ventaService.modificandoReservaValue) {
      this.paymentService.setMostrarBotonesModificar(true);
      this.paymentService.mostrarBotonesModificar$
        .pipe(take(1))
        .subscribe((mostrar) => (this.mostrarModificarReserva = mostrar));
    }
    if (JSON.parse(enlaceMenu.chBotones).Comprar === 1) {
      this.paymentService.setMostrarBotonesPagar(true);
      this.paymentService.mostrarBotonesPagar$
        .pipe(take(1))
        .subscribe((mostrar) => (this.mostrarComprar = mostrar));
    }
    if (JSON.parse(enlaceMenu.chBotones).Reservar === 1) {
      this.paymentService.setMostrarBotonesReservar(true);
      this.paymentService.mostrarBotonesReservar$
        .pipe(take(1))
        .subscribe((mostrar) => (this.mostrarReservar = mostrar));
    }
    if (JSON.parse(enlaceMenu.chBotones).PhoneSell === 1) {
      this.paymentService.setMostrarBotonesPhone(true);
      this.paymentService.mostrarBotonesPhone$
        .pipe(take(1))
        .subscribe((mostrar) => (this.mostrarPhone = mostrar));
    }
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      this.paymentService.setMostrarBotonesPagar(false);
      this.paymentService.setMostrarBotonesPhone(false);
    }
    //Cargamos el valor del boton de Bizum del proyecto
    if (
      this.getFormasPagoProyecto(
        this.configurationService.configurationValue.datosProyecto
          .CaracteristicasAdicionales
      ).bizum
    ) {
      this.paymentService.setMostrarBotonesBizum(true);
      this.paymentService.mostrarBotonesBizum$
        .pipe(take(1))
        .subscribe((mostrar) => (this.mostrarBizum = mostrar));
    }
    //Cargamos el valor del boton de Paypal del proyecto
    if (
      this.getFormasPagoProyecto(
        this.configurationService.configurationValue.datosProyecto
          .CaracteristicasAdicionales
      ).paypal
    ) {
      this.paymentService.setmostrarBotonesPaypal(true);
      this.paymentService.mostrarBotonesPaypal$
        .pipe(take(1))
        .subscribe((mostrar) => (this.mostrarPaypal = mostrar));
    }
    //Comprobamos mostrarIncluirGestion
    if (
      JSON.parse(enlaceMenu.CaracteristicasAdicionales)
        .CaracteristicasAdicionales.MostrarIncluyeComision
    ) {
      this.ventaService.setGestionarComision(true);
    }
  }

  cargarCarritoTraerReserva() {
    if (this.ventaService.modificandoReservaValue) {
      this.initService
        .getInitData()
        .pipe(take(1))
        .subscribe(() =>
          this.paymentService
            .traerReserva(this.ventaService.modificandoReservaValue, "", 1)
            .pipe(take(1))
            .subscribe((productos) => {
              console.log("productos", productos)
              this.provinciaCliente = productos.clienteCompra
                ? productos.clienteCompra.ProvinciaContacto
                  ? productos.clienteCompra.ProvinciaContacto
                  : ""
                : "";
              if (productos.Pagada === "1") {
                this.ventaService.setIsReservaPagadaValue(true);
                sessionStorage.setItem("estaPagada", "1");
              } else {
                sessionStorage.removeItem("estaPagada");
                this.ventaService.setIsReservaPagadaValue(false);
              }
              if (productos.ProductosCompra[0].Comision === "0.0000") {
                sessionStorage.removeItem("tieneComision");
              } else {
                sessionStorage.setItem("tieneComision", "1");
              }
              if (
                sessionStorage.getItem("currentUser") ||
                sessionStorage.getItem("admin")
              ) {
                this.productosService.setPlataformaReserva(
                  productos.Plataforma
                );
                sessionStorage.setItem(
                  "currentUser",
                  JSON.stringify(productos.clienteInternet)
                );
                this.user = JSON.parse(sessionStorage.getItem("currentUser"));
                this.compradorForm
                  .get("PublicidadInterna")
                  .setValue(this.user.PublicidadInterna);
              }

              if (productos.Adelanto) {
                if (parseInt(productos.Adelanto) > 0) {
                  this.paymentService.settieneAdelanto(true);
                }
              }
              this.ventaService.setEnlaceMenuCarrito(
                productos.listLocalizadores[0].EnlaceMenu
              );
              const enlaceMenu = this.productosService.enlacesMenuValue.find(
                (e) => e.pkid === productos.listLocalizadores[0].EnlaceMenu
              );
              this.ventaService.mapCliente(productos.clienteCompra, productos);
              this.cargarUsuario();
              this.productosService.setDatosInitValue({
                empresa: this.proyecto,
                m: enlaceMenu.chNombre,
              });
              this.productosService.getDescuento();
              if (this.config.getProyecto() === "SNOWZONE") {
                const emHeader = this.initService.getEmHeader(enlaceMenu);
                this.productosService
                  .getTodosProductos(emHeader)
                  .pipe(take(1))
                  .subscribe((res) =>
                    this.productosService.setProductosHeaderValue(
                      res.DictTarifasEntradas &&
                        Object.values(res.DictTarifasEntradas).length > 0
                        ? Object.values(res.DictTarifasEntradas)
                        : res.ListTarifasEntradas
                    )
                  );
              }
              this.cargarOpcionesCarrito(enlaceMenu);
              this.ventaService.ordenacionTraerReserva(productos);
              sessionStorage.setItem(
                "reservaOriginal",
                JSON.stringify(this.ventaService.carritoValue)
              );
              this.ventaService.guardarCesta();

              // this.cargarFiltros();
              this.ordenarCarrito();
              this.productosService.setCargandoComponente2Value(false);
            })
        );
    }
  }

  getFormasPagoProyecto(
    jsonCaracteristicasAdicionales
  ): ModelFormasPagoProyecto {
    var respuesta = new ModelFormasPagoProyecto();
    try {
      let caracteristicasAdicionales = JSON.parse(
        jsonCaracteristicasAdicionales
      );
      if (
        caracteristicasAdicionales != null &&
        caracteristicasAdicionales.CaracteristicasAdicionales != null
      ) {
        respuesta.bizum =
          caracteristicasAdicionales.CaracteristicasAdicionales.pagoPorBizum !=
            null &&
          caracteristicasAdicionales.CaracteristicasAdicionales.pagoPorBizum ==
            "1"
            ? true
            : false;
        respuesta.paypal =
          caracteristicasAdicionales.CaracteristicasAdicionales.pagoPorPayPal !=
            null &&
          caracteristicasAdicionales.CaracteristicasAdicionales.pagoPorPayPal ==
            "1"
            ? true
            : false;
        //Habria que hacer lo mismo para PayPal :P
      }
    } catch (e) {}
    return respuesta;
  }

  tieneInfoCompra(num) {
    let info = this.translateService.data["InfoCompra" + num] || "";
    return info.length > 0;
  }

  openModalPoliticas() {
    switch (this.proyecto) {
      case "museocr":
        window.open("https://museocr.org/terminos-y-condiciones/", "_blank");
        break;
      default:
        this.modalService.open("politicas", true);
    }
  }

  getVentaService() {
    return this.ventaService;
  }

  handleCancel() {
    this.modalService.open("modalCarritoCancelar", true);
  }
}
