import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterContentInit,
} from "@angular/core";
import { VentaService } from "../../../../core/services/venta.service";
import { ProductosService } from "src/app/business/productos.service";
import { map, mergeMap, take, takeUntil } from "rxjs/operators";
import { Producto } from "src/app/models/producto.model";
import { Router } from "@angular/router";
import { InitService } from "src/app/core/init/init.service";
import { CalendarService } from "./calendar/calendar.service";
import { ClientDataService } from "../client-data/client-data.service";
import { Compra } from "src/app/models/compra.model";
import { CompraCarrito } from "src/app/models/compra-carrito.model";
import { ConfigurationService } from "src/app/core/config/configuration.service";
import { of, Subject } from "rxjs";
import { SesionService } from "src/app/core/services/sesion.service";
import { AppConfiguration } from "src/app/app.configuration";
import { TranslateService } from "src/app/shared/translate.service";
import { FiltrosComponent } from "./filtros/filtros.component";
import { GoogleAnalyticsEventsServiceService } from "src/app/shared/google-analytics-events-service.service";
import { ModalService } from "src/app/shared/modal.service";
import { TimerService } from "src/app/core/services/timer.service";

declare var fbq;
@Component({
  selector: "app-date-selector",
  templateUrl: "./date-selector.component.html",
  styleUrls: ["./date-selector.component.scss"],
})
export class DateSelectorComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  private ngUnsubscribe = new Subject();
  productos$;
  pantallas: [];
  mostrar = this.calendarService.mostrarValue;
  sinCampos = this.productosService.sinCamposValue;
  tieneHoras = false;
  entradas: any[] = [];
  compra: CompraCarrito;
  mensaje;
  loading: boolean;
  aviso;
  mostrarMotivos: boolean;
  idProductoMotivos;
  @ViewChild(FiltrosComponent, { read: FiltrosComponent })
  childFiltros: FiltrosComponent;
  opcionesMotivos: any[] = [];
  sumatoriaMotivos = 0;
  proyecto = this.config.getProyecto(); //Yaribel 20210713 añadimos proyecto para obtener los assets genericamente
  isEditMode: boolean;
  mostrarProductoGrande = false;
  posicionVentaInicial;

  constructor(
    private calendarService: CalendarService,
    private initService: InitService,
    private ventaService: VentaService,
    private productosService: ProductosService,
    private router: Router,
    private clientDataService: ClientDataService,
    private configurationService: ConfigurationService,
    private sesionService: SesionService,
    private config: AppConfiguration,
    private translateService: TranslateService,
    private GAService: GoogleAnalyticsEventsServiceService,
    private modalService: ModalService,
    private timerService: TimerService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.productosService.setDataRegistro();

    this.posicionVentaInicial =
      this.ventaService.compraValue.productos[0] &&
      this.ventaService.compraValue.productos[0].posicionVenta
        ? this.ventaService.compraValue.productos[0].posicionVenta
        : this.ventaService.calcularposicionVenta(); //Yaribel 20220628 Obtenemos la posicion venta a gestionar para mantenerla aunque cambiemos los productos de la compra
    this.calendarService.setCalendarioPaso2(false);
    this.sesionService.setSesiones([]);
    this.sesionService.setSesionesProfesor([]);
    this.productosService.tieneSesiones = false;
    this.isEditMode = false;
    this.productosService.cargandoComponente2$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((carga) => (this.loading = carga));
    this.calendarService.setMostrar(true);
    const dobleLayoutEntradas = JSON.parse(
      this.productosService.getEnlaceMenuId().CaracteristicasAdicionales
    ).CaracteristicasAdicionales.DobleLayoutEntradas;
    if (dobleLayoutEntradas) {
      // tslint:disable-next-line: radix
      if (parseInt(dobleLayoutEntradas) > 0) {
        this.mostrarProductoGrande = true;
      }
    }
    const caracteristicasCalendario =
      this.initService.chCalendarioValue.CaracteristicasCalendario;
    const grupoInternet = parseInt(
      this.productosService.getGrupoInternet().gi,
      10
    );
    this.calendarService.mostrarCalendario(
      "Paso1",
      caracteristicasCalendario,
      grupoInternet
    );
    this.calendarService.mostrar$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((mostrar) => (this.mostrar = mostrar));
    if (this.mostrar) {
      this.productosService.productosList = of([]);
    } else {
      this.productosService.productosList =
        this.productosService.productos$.pipe(
          map((productos) => Object.values(productos))
        );
    }
    this.clientDataService
      .getIdCamposData()
      .pipe(
        mergeMap((id: any) => {
          return this.clientDataService.getCamposData(id);
        })
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((campos) => {
        if (campos.LstCampos.length === 0) {
          this.productosService.setSinCamposValue(true);
        } else {
          this.productosService.setSinCamposValue(false);
        }

        //timer - si tenemos productos disponibles
        this.timerService.start();
      });
  }

  ngAfterContentInit() {
    if (this.initService.aceptaCookieGoolgeValue)
      this.GAService.emitEvent("event", "paso-1"); //Yaribel 20210514 añadimos  la petición al servicio para registrar el evento
    this.GAService.callStepView("dateSelector");

    //timer - si tenemos productos disponibles
    this.timerService.start();
  }

  cambiarValorMostrarMotivos(producto) {
    this.idProductoMotivos = producto.ProductoId;
    this.mostrarMotivos = !this.mostrarMotivos;
    if (this.mostrarMotivos) {
      this.productosService
        .getMotivos(producto.GrupoMotivoId)
        .subscribe((res) => {
          this.opcionesMotivos = res.customanItemMotGrupo;
        });
    }
  }

  switchToEditMode() {
    this.isEditMode = !this.isEditMode;
  }

  cambioCantidad(value, producto) {
    const numero = parseInt(value, 10);
    const cantidadInicial = this.getCantidadPorProducto(producto);
    const diferencia = numero - cantidadInicial;
    if (value === "") {
      const numero1 = 0;
      const diferencia1 = numero1 - cantidadInicial;
      for (let i = 0; i < diferencia1 * -1; i++) {
        this.eliminarProducto(producto);
      }
    }
    if (isNaN(value)) {
      this.getCantidadPorProducto(producto);
    }
    if (diferencia < 0) {
      if (producto.MinimoTarifa) {
        const minimo = parseInt(producto.MinimoTarifa, 10);
        if (numero < minimo) {
          this.eliminarProductoTodos(producto);
        } else {
          for (let i = 0; i < diferencia * -1; i++) {
            this.eliminarProductoTeclado(producto);
          }
        }
      }
    }
    if (diferencia > 0) {
      if (producto.MinimoTarifa) {
        const minimo = parseInt(producto.MinimoTarifa, 10);
        if (numero < minimo) {
          this.agregarProducto(producto);
        } else {
          for (let i = 0; i < diferencia; i++) {
            this.agregarProductoTeclado(producto);
          }
        }
      }
    }
  }

  eliminarTodosProductos(producto: any) {
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    this.ventaService.setCambios(true);
    const cantidadEntradas = this.ventaService.getCantidadPorProducto(producto);
    if (cantidadEntradas > 0) {
      this.ventaService.removeProductoModal(producto);
    }
  }

  eliminarProducto(producto: any): void {
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    this.ventaService.setCambios(true);
    const cantidadEntradas = this.ventaService.getCantidadPorProducto(producto);
    if (cantidadEntradas > 0) {
      if (producto.MinimoTarifa) {
        // tslint:disable-next-line: radix
        const minimo = parseInt(producto.MinimoTarifa);
        if (cantidadEntradas === minimo) {
          for (let i = 0; i < minimo; i++) {
            this.ventaService.removeProducto(producto);
          }
        } else {
          this.ventaService.removeProducto(producto);
        }
      } else {
        this.ventaService.removeProducto(producto);
      }
    }
  }

  eliminarProductoTodos(producto: any): void {
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    this.ventaService.setCambios(true);
    const cantidadEntradas = this.ventaService.getCantidadPorProducto(producto);
    if (cantidadEntradas > 0) {
      if (producto.MinimoTarifa) {
        const minimo = parseInt(producto.MinimoTarifa, 10);
        const totalEntradas = minimo + cantidadEntradas;
        if (cantidadEntradas === minimo || cantidadEntradas > minimo) {
          for (let i = 0; i < totalEntradas; i++) {
            this.ventaService.removeProducto(producto);
          }
        }
      }
    }
  }

  agregarProducto(producto: any): void {
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    if (this.avisoFiltrosSinCompletar()) {
      return;
    }
    this.mensaje = "";
    this.ventaService.setCambios(true);
    const cantidadEntradas = this.ventaService.getCantidadPorProducto(producto);
    if (producto.MinimoTarifa) {
      const minimo = parseInt(producto.MinimoTarifa, 10);
      if (cantidadEntradas < minimo) {
        for (let i = 1; i < minimo; i++) {
          this.ventaService.sendProducto(producto);
          this.snowzoneCookies();
        }
      }
    }
    if (producto.MaximoTarifa) {
      if (cantidadEntradas < producto.MaximoTarifa) {
        this.ventaService.sendProducto(producto);
        this.snowzoneCookies();
      }
    } else {
      this.ventaService.sendProducto(producto);
      this.snowzoneCookies();
    }
  }

  eliminarProductoTeclado(producto: any): void {
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    this.ventaService.setCambios(true);
    const cantidadEntradas = this.ventaService.getCantidadPorProducto(producto);
    if (cantidadEntradas > 0) {
      this.ventaService.removeProducto(producto);
    }
  }

  agregarProductoTeclado(producto: any): void {
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    if (this.avisoFiltrosSinCompletar()) {
      return;
    }
    this.mensaje = "";
    this.ventaService.setCambios(true);
    const cantidadEntradas = this.ventaService.getCantidadPorProducto(producto);
    if (producto.MaximoTarifa) {
      if (cantidadEntradas < producto.MaximoTarifa) {
        this.ventaService.sendProducto(producto);
        this.snowzoneCookies();
      }
    } else {
      this.ventaService.sendProducto(producto);
      this.snowzoneCookies();
    }
  }

  getSumatoriaCantidadesMotivos(opc: any, operacion: string, producto: any) {
    if (JSON.parse(sessionStorage.getItem("estaPagada"))) {
      return;
    }
    switch (operacion) {
      case "s": {
        if (this.sumatoriaMotivos < producto.MaximoTarifa) {
          const prodConG = this.agregarMotivo(producto, operacion, opc);
          this.agregarProducto(prodConG);
        }
        break;
      }
      case "r": {
        const productoSeleccionado =
          this.ventaService.compraValue.productos.find(
            (p) => p.producto.ProductoId === producto.ProductoId
          );
        if (productoSeleccionado) {
          const canitdadMotivo = productoSeleccionado.producto.motivos.find(
            (m) => m.PkId === opc.PkId
          ).cantidad;
          if (canitdadMotivo > 0) {
            producto.motivoId = opc.PkId;
            const prodConG = this.agregarMotivo(producto, operacion, opc);
            this.eliminarProducto(prodConG);
          }
        }
        break;
      }
    }
    let cantidadMotivos = 0;
    const productoSeleccionado = this.ventaService.compraValue.productos.find(
      (p) => p.producto.ProductoId === producto.ProductoId
    );
    if (productoSeleccionado) {
      productoSeleccionado.producto.motivos.forEach((element) => {
        cantidadMotivos += parseInt(element.cantidad, 10);
      });
    }
    this.sumatoriaMotivos = cantidadMotivos;
  }

  agregarMotivo(producto, operacion, motivo) {
    const listaCompra = this.ventaService.compraValue;
    switch (operacion) {
      case "s": {
        const prodList = listaCompra.productos.find(
          (prod) => prod.producto.ProductoId === producto.ProductoId
        );
        const flag1 = prodList ? prodList.producto.motivos : undefined;
        if (flag1) {
          const mot = prodList.producto.motivos.find(
            (grat) => grat.PkId === motivo.PkId
          );
          if (mot) {
            mot.cantidad++;
          } else {
            prodList.producto.motivos.push({
              PkId: motivo.PkId,
              cantidad: 1,
              nombre: motivo.Nombre,
            });
          }
        } else {
          producto.motivos = [
            { PkId: motivo.PkId, cantidad: 1, nombre: motivo.Nombre },
          ];
        }
        break;
      }
      case "r": {
        const prodList = listaCompra.productos.find(
          (prod) => prod.producto.ProductoId === producto.ProductoId
        );
        const mot = prodList.producto.motivos.find(
          (grat) => grat.PkId === motivo.PkId
        );
        if (mot.cantidad > 0) {
          mot.cantidad--;
          if (mot.cantidad === 0) {
            prodList.producto.motivos = prodList.producto.motivos.filter(
              (grat) => grat.PkId !== motivo.PkId
            );
          }
        }
        break;
      }
    }
    return producto;
  }

  getCantidadMotivosPorProductoIdMotivo(producto, motivoId) {
    return this.ventaService.getCantidadMotivosPorProductoIdMotivo(
      producto,
      motivoId
    );
  }

  getCantidadPorProducto(producto: Producto): number {
    if (this.ventaService.compraValue) {
      return this.ventaService.getCantidadPorProducto(producto);
    }
    return 0;
  }

  isDisabled() {
    if (this.mostrar) {
      if (
        this.ventaService.compraValue.productos.length > 0 &&
        this.calendarService.diaDisponibleValue
      ) {
        return false;
      } else if (
        JSON.parse(sessionStorage.getItem("estaPagada")) &&
        this.ventaService.compraValue.productos.length > 0 &&
        !this.calendarService.diaDisponibleValue
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (this.ventaService.compraValue.productos.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  getDiasCursillo(item) {
    if (this.config.getProyecto() === "SNOWZONE") {
      if (this.productosService.getGrupoInternet().gi !== 10) {
        return;
      } else {
        const tarifa = this.sesionService.sesiones.find(
          (s) =>
            s.Fecha ===
            this.ventaService.fechaSeleccionadaValue.format("DD/MM/YYYY")
        );
        if (tarifa) {
          const dias = tarifa.Comentarios.split(",");
          let diasBien = "";
          dias.forEach((dia) => {
            diasBien = diasBien + (dia + ", ");
          });
          return (
            ". " +
            this.translateService.data.DIASCURSILLO +
            diasBien.slice(0, -2) +
            "."
          );
        }
      }
    }
  }

  forwardClick() {
    if (this.avisoFiltrosSinCompletar()) {
      return;
    }
    if (!this.controlCantidades()) {
      return;
    }
    if (this.productosService.tieneSesiones) {
      if (!this.controlCuentaVisitante()) {
        this.mensaje = this.translateService.data.SELECCIONAPARTICIPANTE;
        return;
      }
    }
    if (!this.controlValidarGratuitas()) {
      this.mensaje = this.translateService.data.SELECCIONAGRATUITA;
      return;
    }
    if (this.controlEntradaAgrupada()) {
      this.mensaje = this.translateService.data.AVISOAGRUPADA;
      return;
    }
    let posicionVenta = this.ventaService.compraValue.productos[0].posicionVenta
      ? this.ventaService.compraValue.productos[0].posicionVenta
      : //this.ventaService.calcularposicionVenta();
        this.posicionVentaInicial;
    if (this.productosService.sinCamposValue) {
      if (this.productosService.tieneSesiones) {
        if (this.ventaService.modificarValue) {
          this.cancelarAforoEliminarProdCarrito();
        }
        let checkDictTarifasRecintos = false;
        for (let p in this.productosService.productosValue) {
          if (
            Object.keys(
              this.productosService.productosValue[p].DictTarifasRecintos
            ).length > 0
          ) {
            checkDictTarifasRecintos = true;
          }
        }
        let checkControlAforoDictTarifasRecintos = false;
        if (checkDictTarifasRecintos) {
          let arraySinSession = [];
          for (let p in this.productosService.productosValue) {
            if (
              Object.keys(
                this.productosService.productosValue[p].DictTarifasRecintos
              ).length > 0
            ) {
              for (let sp in this.productosService.productosValue[p]
                .DictTarifasRecintos) {
                if (
                  this.productosService.productosValue[p].DictTarifasRecintos[
                    sp
                  ].ControlaAforo == 1
                ) {
                  checkControlAforoDictTarifasRecintos = true;
                }
              }
            } else {
              arraySinSession =
                this.ventaService.fechaSeleccionadaSinSessionValue;
              if (
                arraySinSession.find(
                  (find) =>
                    find.posicionVenta == posicionVenta &&
                    find.productoId ==
                      this.productosService.productosValue[p].ProductoId
                )
              ) {
                const index = arraySinSession.findIndex(
                  (find) =>
                    find.posicionVenta == posicionVenta &&
                    find.productoId ==
                      this.productosService.productosValue[p].ProductoId
                );
                arraySinSession[index] = {
                  ...arraySinSession[index],
                  productoId:
                    this.productosService.productosValue[p].ProductoId,
                  posicionVenta: posicionVenta,
                  fecha: this.ventaService.fechaSeleccionadaValue,
                };
              } else {
                arraySinSession.push({
                  productoId:
                    this.productosService.productosValue[p].ProductoId,
                  posicionVenta: posicionVenta,
                  fecha: this.ventaService.fechaSeleccionadaValue,
                });
              }
              this.ventaService.setFechaSeleccionadaSinSessionValue(
                arraySinSession
              );
              this.ventaService.guardarFechaSeleccionadasSinSesion();
            }
          }
          if (checkControlAforoDictTarifasRecintos) {
            this.traerSesiones();
          } else {
            this.step1(posicionVenta);
          }
        } else {
          this.step1(posicionVenta);
        }
      } else if (this.calendarioPaso2()) {
        if (this.ventaService.modificarValue) {
          this.cancelarAforoEliminarProdCarrito();
        }
        // if(this.initService.aceptaCookieGoolgeValue)
        // this.GAService.emitEvent('event', 'paso-1'); //Yaribel 20210514 añadimos  la petición al servicio para registrar el evento
        this.router.navigate(["/tienda/producto/2"]);
      } else {
        if (this.ventaService.modificarValue) {
          posicionVenta = this.ventaService.compraValue.productos[0]
            .posicionVenta
            ? this.ventaService.compraValue.productos[0].posicionVenta
            : this.posicionVentaInicial;
          this.ventaService.carritoValue.eliminarProductosPorposicionVenta(
            posicionVenta
          );
        }
        this.ventaService.compraValue.productos.forEach((producto) => {
          const datos = null;
          const sesionesSeleccionadas = null;
          this.compra = {
            cantidad: producto.cantidad,
            posicionVenta,
            producto: producto.producto,
            datos,
            sesionesSeleccionadas,
          };
          this.ventaService.sendCompra(this.compra);
        });
        this.ventaService.setCompra(new Compra());
        this.ventaService.guardarCesta();
        // if(this.initService.aceptaCookieGoolgeValue)
        // this.GAService.emitEvent('event', 'paso-1'); //Yaribel 20210514 añadimos  la petición al servicio para registrar el evento
        this.router.navigate(["/tienda/resumen"]);
      }
    } else {
      if (this.ventaService.modificarValue) {
        this.cancelarAforoEliminarProdCarrito();
      }
      if (this.productosService.tieneSesiones) {
        this.traerSesiones();
      } else if (this.calendarioPaso2()) {
        // if(this.initService.aceptaCookieGoolgeValue)
        // this.GAService.emitEvent('event', 'paso-1'); //Yaribel 20210514 añadimos  la petición al servicio para registrar el evento
        this.router.navigate(["/tienda/producto/2"]);
      } else {
        // if(this.initService.aceptaCookieGoolgeValue)
        // this.GAService.emitEvent('event', 'paso-1'); //Yaribel 20210514 añadimos  la petición al servicio para registrar el evento
        this.router.navigate(["/tienda/producto/3"]);
      }
    }
  }
  step1(posicionVenta) {
    if (this.ventaService.modificarValue) {
      this.ventaService.carritoValue.eliminarProductosPorposicionVenta(
        posicionVenta
      );
    }
    this.addProduct(posicionVenta);
    if (this.productosService.sinCamposValue) {
      this.ventaService.guardarCesta();
      this.router.navigate(["/tienda/resumen"]);
    } else {
      // if(this.initService.aceptaCookieGoolgeValue)
      // this.GAService.emitEvent('event', 'paso-2'); //Yaribel 20210514 añadimos  la petición al servicio para registrar el evento
      this.router.navigate(["/tienda/producto/3"]);
    }
  }

  // step2(posicionVenta){
  //   this.ventaService.carritoValue.eliminarProductosPorposicionVenta(posicionVenta);
  //   this.step1(posicionVenta);
  // }
  addProduct(posicionVenta) {
    const listadoProductos = [];
    let arraySinSession = [];
    if (this.calendarService.filtrosValue) {
      this.ventaService.compraValue.productos.forEach((producto) => {
        const prod =
          producto.producto.TipoProducto +
          "," +
          producto.producto.ProductoId +
          "," +
          producto.cantidad +
          ",,,," +
          this.productosService.filtroTipoValue +
          "," +
          this.productosService.filtroGrupoValue +
          "," +
          this.productosService.filtroNivelValue +
          "," +
          this.productosService.filtroIdiomaVisitaValue;
        listadoProductos.push(prod);
      });
    } else {
      this.ventaService.compraValue.productos.forEach((producto) => {
        const prod =
          producto.producto.TipoProducto +
          "," +
          producto.producto.ProductoId +
          "," +
          producto.cantidad;
        listadoProductos.push(prod);
      });
    }
    this.ventaService.compraValue.productos.forEach((producto) => {
      let datos = null;
      const sesionesSeleccionadas = null;
      if (this.calendarService.filtrosValue) {
        datos = {
          cboNivel: this.productosService.filtroNivelValue,
          cboTipoTabla: this.productosService.filtroTipoValue,
          cboRangoEdad: this.productosService.filtroGrupoValue,
          cboIdiomaVisita: this.productosService.filtroIdiomaVisitaValue,
        };
      }
      this.compra = {
        cantidad: producto.cantidad,
        posicionVenta,
        producto: producto.producto,
        datos,
        sesionesSeleccionadas,
      };
      arraySinSession = this.ventaService.fechaSeleccionadaSinSessionValue;

      const arraySinSesionExist = arraySinSession.find(
        (find) =>
          find.posicionVenta == producto.posicionVenta &&
          find.productoId == producto.producto.ProductoId
      );

      if ( arraySinSesionExist ) {
        console.log({ arraySinSessionFind: true });
        const index = arraySinSession.findIndex(
          (find) =>
            find.posicionVenta == producto.posicionVenta &&
            find.productoId == producto.producto.ProductoId
        );
        arraySinSession[index] = {
          ...arraySinSession[index],
          productoId: producto.producto.ProductoId,
          posicionVenta: posicionVenta,
          fecha: this.ventaService.fechaSeleccionadaValue,
        };
      } else {
        arraySinSession.push({
          productoId: producto.producto.ProductoId,
          posicionVenta: posicionVenta,
          fecha: this.ventaService.fechaSeleccionadaValue,
        });
      }
      this.ventaService.setFechaSeleccionadaSinSessionValue(arraySinSession);
      this.ventaService.guardarFechaSeleccionadasSinSesion();
      this.ventaService.sendCompra(this.compra);
    });
  }
  avisoFiltrosSinCompletar() {
    if (this.calendarService.filtrosValue) {
      let filtrosSinCompletar = true;
      const filtros = this.calendarService.filtrosObjectValue;
      if (filtros.IdiomaVisita) {
        filtrosSinCompletar = false;
      }
      if (filtros.NivelEducativo) {
        this.productosService.filtroNivelValue
          ? (filtrosSinCompletar = false)
          : (filtrosSinCompletar = true);
      }
      if (filtros.RangoEdad) {
        this.productosService.filtroGrupoValue
          ? (filtrosSinCompletar = false)
          : (filtrosSinCompletar = true);
      }
      if (filtros.TipoTabla) {
        this.productosService.filtroTipoValue
          ? (filtrosSinCompletar = false)
          : (filtrosSinCompletar = true);
      }
      if (filtros.CalendariosDia) {
        this.productosService.filtroDiasValue
          ? (filtrosSinCompletar = false)
          : (filtrosSinCompletar = true);
      }
      if (filtrosSinCompletar) {
        this.childFiltros.onSubmit();
        this.mensaje = this.translateService.data.FALTANCAMPOS;
        return true;
      }
    }
  }

  controlCantidades() {
    let cantidad = 0;
    this.ventaService.compraValue.productos.forEach((producto) => {
      if (producto.producto.NombreProducto.includes("2 PERSONAS")) {
        cantidad += producto.cantidad * 2;
      } else {
        cantidad += producto.cantidad;
      }
    });

    if (this.productosService.infoGrupoValue) {
      let minimo;
      if (this.productosService.infoGrupoValue.MinimoxGrupo === "0") {
        minimo = 1;
      } else {
        minimo = parseInt(
          this.productosService.infoGrupoValue.MinimoxGrupo,
          10
        );
      }
      if (
        cantidad >
        parseInt(this.productosService.infoGrupoValue.MaximoxGrupo, 10)
      ) {
        this.mensaje =
          this.translateService.data.MAXIMO +
          this.productosService.infoGrupoValue.MaximoxGrupo +
          this.translateService.data.PRODUCTOS;
        return null;
      }
      if (cantidad < minimo) {
        this.mensaje =
          this.translateService.data.MINIMO +
          minimo +
          this.translateService.data.PRODUCTOS;
        return null;
      }
      return "ok";
    }
  }

  controlValidarGratuitas() {
    let pasaControl = true;
    const precioTotal = this.ventaService.compraValue.calcularPrecioTotal();
    const validarGratuitas =
      this.productosService.infoGrupoValue.ValidarGratuitas;
    if (validarGratuitas === "1" && precioTotal === 0) {
      pasaControl = false;
      return pasaControl;
    }
    return pasaControl;
  }

  controlCuentaVisitante() {
    let cVisitante = false;
    this.ventaService.compraValue.productos.forEach((producto) => {
      if (
        producto.producto.CuentaVisitante === "0" &&
        Object.keys(producto.producto.DictTarifasRecintos).length == 0
      ) {
        cVisitante = true;
        return cVisitante;
      }
      if (producto.producto.CuentaVisitante === "1") {
        cVisitante = true;
        return cVisitante;
      }
      if (producto.producto.ProductosBono.length) {
        producto.producto.ProductosBono.forEach((pBono) => {
          if (pBono.CuentaVisitante === "1") {
            cVisitante = true;
            return cVisitante;
          }
        });
      }
    });
    return cVisitante;
  }

  traerSesiones() {
    const listadoProductos = [];
    if (this.calendarService.filtrosValue) {
      this.ventaService.compraValue.productos.forEach((producto) => {
        const prod =
          producto.producto.TipoProducto +
          "," +
          producto.producto.ProductoId +
          "," +
          producto.cantidad +
          ",,,," +
          this.productosService.filtroTipoValue +
          "," +
          this.productosService.filtroGrupoValue +
          "," +
          this.productosService.filtroNivelValue;
        listadoProductos.push(prod);
      });
    } else {
      this.ventaService.compraValue.productos.forEach((producto) => {
        const prod =
          producto.producto.TipoProducto +
          "," +
          producto.producto.ProductoId +
          "," +
          producto.cantidad;
        listadoProductos.push(prod);
      });
    }
    this.productosService
      .traerSesiones(listadoProductos)
      .pipe(take(1))
      .subscribe((sesiones) => {
        const sesionesConMin = this.sesionService.minVentaControl(
          sesiones,
          this.productosService.getEnlaceMenuId()
        );
        const sesionesConMinMax = this.sesionService.maxVentaControl(
          sesionesConMin,
          this.productosService.getEnlaceMenuId()
        );
        this.sesionService.setSesiones(sesionesConMinMax);
        const producto = this.ventaService.compraValue.productos[0];
        const tieneSesiones = this.sesionService.getSesionesPorProducto(
          producto.producto
        );
        if (tieneSesiones.length) {
          this.router.navigate(["/tienda/producto/2"]);
        } else {
          this.mensaje = this.translateService.data.NOSESIONES;
        }
      });
  }

  snowzoneCookies() {
    if (this.config.getProyecto() === "SNOWZONE") {
      const cookies = JSON.parse(sessionStorage.getItem("cookies"));
      const facebook = cookies.find((c) => c.Nombre === "Facebook (_fbq)");
      if (facebook.aceptar === true) {
        fbq("track", "AddToCart");
      }
    }
  }

  calendarioPaso2(): boolean {
    return this.calendarService.calendarioPaso2Value;

    // this.calendarService.setCalendarioPaso2(true);
    // let calendarioEnPaso2 = true;
    // const mostrarCalendarioPaso = this.initService.chCalendarioValue.CaracteristicasCalendario.Visibilidad;

    // if (mostrarCalendarioPaso !== 'Paso2') {
    //   calendarioEnPaso2 = false;
    //   this.calendarService.setCalendarioPaso2(false);
    //   return calendarioEnPaso2;
    // } else {
    //   if (this.pantallas) {
    //     this.pantallas.find(res => {
    //       if (res === parseInt(this.productosService.getGrupoInternet().gi, 10)) {
    //         calendarioEnPaso2 = false;
    //         this.calendarService.setCalendarioPaso2(false);
    //         return calendarioEnPaso2;
    //       }
    //     });
    //   }
    // }
    // return calendarioEnPaso2;
  }

  cancelarAforoEliminarProdCarrito() {
    if (this.ventaService.cambiosValue === false) {
      return;
    }
    if (!this.ventaService.modificandoReservaValue) {
      this.ventaService.setModificarValue(false);
      const posicionVenta = this.ventaService.compraValue.productos[0]
        .posicionVenta
        ? this.ventaService.compraValue.productos[0].posicionVenta
        : this.posicionVentaInicial;

      this.ventaService.carritoValue.eliminarProductosPorposicionVenta(
        posicionVenta
      );
      this.ventaService.compraValue.productos.forEach((producto) => {
        producto.sesionesSeleccionadas = null;
      });
      this.ventaService.setCarrito(this.ventaService.carritoValue);
      this.ventaService.guardarCesta();
      this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
      this.productosService.reservaAforoCarrito();
    }
  }

  controlEntradaAgrupada() {
    const productoSinAgrupar = this.ventaService.compraValue.productos.find(
      (p) => p.producto.Agrupar === "0"
    );
    if (productoSinAgrupar) {
      return false;
    } else {
      return true;
    }
  }

  tieneMotivos(producto): boolean {
    if (parseInt(producto.GrupoMotivoId, 10)) {
      return true;
    } else {
      return false;
    }
  }

  mostrarCardMotivos(producto) {
    if (this.mostrarMotivos && producto.ProductoId === this.idProductoMotivos) {
      return true;
    } else {
      return false;
    }
  }

  onImgError(event) {
    event.target.src = "./assets/img/" + this.proyecto + "/default.png";
    //Do other stuff with the event.target
  }
  getDivisasValue() {
    return this.configurationService.divisasValue;
  }

  getProductosService() {
    return this.productosService;
  }

  getPrecioPorProducto(producto: Producto): number {
    if (producto.DesglosarComision == "0") {
      return (
        Number.parseFloat(
          Number.parseFloat(
            producto.ComisionInternet.replace(",", ".")
          ).toFixed(4)
        ) +
        Number.parseFloat(
          Number.parseFloat(producto.PVPSinDescuento.replace(",", ".")).toFixed(
            4
          )
        )
      );
    } else {
      return producto.PVPSinDescuento;
    }
  }
}
